import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useAuth } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const Price = () => {
  const { apipath } = useAuth();
  const { courseId } = useParams();
  const [courses, setCourses] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("userEmail");
  const [dateSlots, setDateSlots] = useState([]); // For the list of slots
  const [selectedSlot, setSelectedSlot] = useState(""); // For the selected slot
  const [homePageData, setHomePageData] = useState(null);

  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await fetch(`${apipath}/course/details/${courseId}`);
        if (response.ok) {
          const data = await response.json();
          setCourses(data.course);

          // Parse the timeSlots JSON string if it's an array with a single JSON string element
          if (
            data.course.timeSlots &&
            Array.isArray(data.course.timeSlots) &&
            data.course.timeSlots.length > 0
          ) {
            const parsedSlots = JSON.parse(data.course.timeSlots[0] || "[]");
            setTimeSlots(parsedSlots);
          }

          // Parse the dateSlots JSON string to get the startDate and endDate values
          if (
            data.course.dateSlots &&
            Array.isArray(data.course.dateSlots) &&
            data.course.dateSlots.length > 0
          ) {
            const parsedDates = JSON.parse(data.course.dateSlots[0] || "[]");
            setDateSlots(parsedDates);
          }
        } else {
          throw new Error("Failed to fetch course details");
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchCourseDetails();
  }, [courseId, apipath]);

  useEffect(() => {
    async function fetchHomePageData() {
      try {
        const response = await axios.get(apipath + '/homePage/details');
        setHomePageData(response.data.homePage[0]);
      } catch (error) {
        console.error('Error fetching home page data:', error);
      }
    }
    fetchHomePageData();
  }, []);

  const handlePaymentClick = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      iziToast.error({
        message: "Please log in to continue.",
        position: "topCenter",
      });
      localStorage.setItem("redirectAfterLogin", `/price/${courseId}`);
      navigate("/signin");
      return;
    }

    if (!courses || !selectedSlot) {
      iziToast.error({
        message: "Please select a date slot.",
        position: "topCenter",
      });
      return;
    }

    // Prepare booking data
    const bookingData = {
      bookingCourse: courses.courseName,
      bookingTimeSlot: selectedTimeSlot,
      bookingInstructor: courses.instructor,
      bookingPrice: courses.fee,
      bookingGST: courses.gst,
      totalPrice: courses.fee + courses.gst,
      orderPlacedDate: new Date().toISOString(),
      orderConfirmed: 1,
      bookingId: "", // This will be filled after booking is saved
      paymentStatus: false,
      bookingStatus: false,
      course_id: courses._id,
      priceOffer: courses.priceOffer,
      classModel: courses.classModel,
      slotDate: selectedSlot,
    };

    try {
      // Save booking data
      const bookingResponse = await fetch(`${apipath}/booking/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bookingData),
      });

      if (bookingResponse.status === 201) {
        const bookingResult = await bookingResponse.json();

        // Assuming the API returns the `bookingId` as `_id` or some other field, adjust accordingly
        const bookingId = bookingResult.booking._id;

        // Store bookingId in localStorage
        localStorage.setItem("bookingId", bookingId);

        iziToast.success({
          message: "Booking added successfully",
          position: "topCenter",
        });

        // Proceed to payment
        const paymentResponse = await axios.post(`${apipath}/payment/booking`, {
          course_id: courseId,
          _id: bookingId, // Use the newly saved bookingId
          totalPrice: courses.fee + courses.gst,
          userEmail: userEmail,
          courseName: courses.courseName,
        });

        if (paymentResponse.status === 200) {
          window.location.href = paymentResponse.data.url;
        } else {
          iziToast.error({
            message: "Failed to initiate payment.",
            position: "topCenter",
          });
        }
      } else {
        iziToast.error({
          message: "Booking addition failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error during booking/payment:", error);
      iziToast.error({
        message:
          error.response?.data?.message ||
          "An error occurred during booking/payment.",
        position: "topCenter",
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container price">
        <div className="text-box-align">
          <div className="p-header para-text">
            {courses && (
              <>
                <h1>
                  <span>{courses.courseName}</span>
                </h1>
                <div
                  dangerouslySetInnerHTML={{ __html: courses.descriptionPrice }}
                />
              </>
            )}
          </div>
          {homePageData && (
            <>
          <div className="p-card1 text-box">
            <div>
            <div
                    dangerouslySetInnerHTML={{ __html: homePageData.paymentText }}
                  />
                </div>
          </div>
          </>
        )}
        </div>
        <div className="p-cards row">
          {courses && (
            <>
              <div className="col-lg-6">
                <div className="p-card1">
                  <div>
                    <h2>{courses.courseName}</h2>
                    <h4>
                      {new Date(
                        `1970-01-01T${courses.startTimes}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      to{" "}
                      {new Date(
                        `1970-01-01T${courses.endTimes}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      {"IST"}
                    </h4>
                    <h6>
                      Instructor: <span>{courses.instructor}</span>
                    </h6>
                    <h6>
                      Class Mode: <span>{courses.classModel}</span>
                    </h6>
                    <h6>
                      <span>Delivered online from Sydney, Australia</span>
                    </h6>
                  </div>

                  <div>
                    <h4>Fee Price</h4>
                    <h3>USD {courses.fee}</h3>
                  </div>
                </div>
                <div className="p-card2">
                  <label htmlFor="slots">Available Slots</label>
                  <select
                    name="slots"
                    id="slots"
                    onChange={(e) => setSelectedSlot(e.target.value)}
                    value={selectedSlot}
                  >
                    <option value="" disabled>
                      Select your Date slot
                    </option>
                    {dateSlots.length > 0 ? (
                      dateSlots.map((slot, index) => {
                        const startDate = new Date(slot.startDate);
                        const endDate = new Date(slot.endDate);

                        // Format both startDate and endDate
                        const formattedStartDate = `${String(startDate.getDate()).padStart(2, "0")}-${String(startDate.getMonth() + 1).padStart(2, "0")}-${startDate.getFullYear()}`;
                        const formattedEndDate = `${String(endDate.getDate()).padStart(2, "0")}-${String(endDate.getMonth() + 1).padStart(2, "0")}-${endDate.getFullYear()}`;

                        return (
                          <option key={index} value={`${slot.startDate}-${slot.endDate}`}>
                            {formattedStartDate} to {formattedEndDate}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No slots available</option>
                    )}
                  </select>
                </div>
              </div>
            </>
          )}

          <div className="col-lg-4">
            {courses && (
              <>
                <div className="p-card3">
                  <h3>{courses.courseName}</h3>
                  <div className="p-line">
                    <span>Course Price</span>
                    <span className="text-cut">$ {courses.priceOffer}</span>
                  </div>
                  <div className="p-line">
                    <span>Course Offer Price</span>
                    <span>$ {courses.fee}</span>
                  </div>
                  <hr />
                  <div className="p-line">
                    <span>Sub-Total</span>
                    <span>$ {courses.fee}</span>
                  </div>
                  <div className="p-line">
                    <span>GST</span>
                    <span>${courses.gst}</span>
                  </div>
                  <hr />
                  <div className="p-line1">
                    <span>Total</span>
                    <span className="p-span">
                      ${(courses.fee + courses.gst).toFixed(2)}
                    </span>
                  </div>

                  <div className="p-proceed">
                    <button onClick={handlePaymentClick}>
                      Proceed Checkout
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Price;

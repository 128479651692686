import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../../AuthContext';
import axios from 'axios';

const CoursesDetails = () => {
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [deleteCoursesId, setDeleteCoursesId] = useState(null);
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const { apipath,filepath } = useAuth();

    useEffect(() => {
        fetchCourses();
        if (!loading && courses.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, courses]);


    const fetchCourses = async () => {
        try {
            const response = await axios.get(apipath + '/course/details');
            setCourses(response.data.course);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setLoading(false);
        }
    };


    const handleShow = (id) => {
        setDeleteCoursesId(id);
        setShow(true);
    };

    const handleClose = () => {
        setDeleteCoursesId(null);
        setShow(false);
    };

    const handleDelete = async () => {
        if (!deleteCoursesId) return;

        try {
            setIsLoading(true);
            await axios.delete(apipath + `/course/details/${deleteCoursesId}`);
            await fetchCourses(); // Refresh users after deletion
            setIsLoading(false);
            handleClose(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting user:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Course</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this course?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Course Details</h5>
                        <div className="add-button">
                            <Link to="/add-course"><button>+ Add Course</button></Link>
                        </div>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Images</th>
                                            <th>Course Name</th>
                                            <th>Price</th>
                                            <th>Time Slot</th>
                                            <th>Instructor</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {courses.map((course, index) => (
                                            <tr key={course._id}>
                                                <td>{index + 1}</td> 
                                                <td> <img
                                                        src={filepath + course.coursePic || `${process.env.PUBLIC_URL}/imgs/Agp-Blog-Jan-3 1.png`}
                                                        width={90} height={90}
                                                        style={{ borderRadius: '35px' }}
                                                    /></td>
                                                <td>{course.courseName}</td>
                                                <td>{course.fee}</td>
                                                <td>{new Date(`1970-01-01T${course.startTimes}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}{" "}to{" "}{new Date(`1970-01-01T${course.endTimes}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</td>

                                                <td>{course.instructor}</td>
                                                <td>
                                                    <Link to={`/courseedit/${course._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> {" "}
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(course._id)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CoursesDetails;

import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';


const ContactSubmit = () => {
  return (
   <>
   <Navbar/>

   <div class="container mb-5">
        <div class="row justify-content-center pt-5 pb-5">
            <div class="col-lg-7">
                <div class="submitted-box">
                <center>
                <img src="imgs/thanks.png" alt="" />
                    <h5 className='pt-4'>Thank you for reaching out. <br/>
                    Our team will contact you back soon.
                    </h5>
                    </center>
                </div>
            </div>
        </div>
    </div>

   <Footer/>
   </>
  )
}

export default ContactSubmit
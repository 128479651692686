import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import axios from "axios";

const BannerImagesAdd = () => {
    const [imageUrl, setImageUrl] = useState(null);
    const [formData, setFormData] = useState({
        bannerImage: null,
    });

    const navigate = useNavigate();
    const { apipath } = useAuth();
    const token = localStorage.getItem('token');

    const handleUploadButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                bannerImage: file,  // Store the file object directly
            });
            setImageUrl(URL.createObjectURL(file));  // Set the preview URL
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('bannerImage', formData.bannerImage);

        try {
            const response = await axios.post(`${apipath}/banner-images/add`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,  // Include token if required
                },
            });

            if (response.status === 201) {
                iziToast.success({
                    message: 'Banner image added successfully',
                    position: 'topCenter',
                });
                navigate('/banner-images-details');
            } else {
                iziToast.error({
                    message: 'Banner image addition failed',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error('Error adding banner image:', error);
            iziToast.error({
                message: 'An error occurred',
                position: 'topCenter',
            });
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <h5>Add Banner Image</h5>
                        <div className="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleRegister}>
                                    <div className="change-profile pt-4 pb-4">
                                        <img
                                            src={imageUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
                                            width={90}
                                            height={90}
                                            style={{ borderRadius: '35px' }}
                                            alt="profile-img"
                                            onClick={handleUploadButtonClick}
                                        />
                                        <h6>Banner Image</h6>
                                        <input
                                            hidden
                                            type="file"
                                            id="fileInput"
                                            name="bannerImage"
                                            onChange={handleChange}
                                            style={{ display: 'none' }}
                                        />
                                    </div>

                                    <div className="">
                                        <button type="submit" className="btn btn-success">
                                            Add Banner Image
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BannerImagesAdd;

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Courses = () => {
  const [courseData, setCourseData] = useState([]);
  const { apipath } = useAuth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    async function fetchCourseData() {
      try {
        const response = await axios.get(apipath + "/course/details");
        setCourseData(response.data.course);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    }
    fetchCourseData();
  }, [apipath]);

  return (
    <>
      <Navbar />

      <div className="container courses">
        <div className="c-heading">
          <h1>My Courses</h1>
        </div>
        <div className="row c-row">
          {courseData &&
            courseData.map((course) => (
              <div key={course._id} className="col-lg-6">
                <Link
                  to={`/details/${course._id}`}
                  className="text-decoration-none"
                >
                  <div className="c-card">
                    <h2>{course.courseName}</h2>
                    {/* <p>{course.description}</p> */}
                    {/* <h5>
                      Instructor: <span>{course.instructor}</span>
                    </h5> */}
                    <h5>
                      {new Date(
                        `1970-01-01T${course.startTimes}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      to{" "}
                      {new Date(
                        `1970-01-01T${course.endTimes}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      {"IST"}
                    </h5>
                    <h5>
                      Instructor: <span>{course.instructor}</span>
                    </h5>
                    <h5>
                      Class Mode: <span>{course.classModel}</span>
                    </h5>
                    <h6>
                      <span>Delivered online from Sydney, Australia</span>
                    </h6>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Courses;

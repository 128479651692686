import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../../AuthContext';
import axios from 'axios';

const BlogCommentDetails = () => {
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [deleteBlogCommentId, setDeleteBlogCommenttId] = useState(null); // Track the user ID to delete
    const [blogComment, setBlogComment] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const { apipath } = useAuth();

    useEffect(() => {
        fetchBlogComment();
        if (!loading && blogComment.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, blogComment]);

  
    const fetchBlogComment = async () => {
        try {
          const response = await axios.get(apipath + '/blogComment/details');
          setBlogComment(response.data.blogComment);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching blogComment:', error);
          setLoading(false);
        }
      };

    const handleShow = (id) => {
        setDeleteBlogCommenttId(id);
        setShow(true);
    };

    const handleClose = () => {
        setDeleteBlogCommenttId(null);
        setShow(false);
    };

    const handleDelete = async () => {
        if (!deleteBlogCommentId) return;

        try {
            setIsLoading(true);
            await axios.delete(apipath + `/contactUs/details/${deleteBlogCommentId}`);
            await fetchBlogComment(); // Refresh users after deletion
            setIsLoading(false);
            handleClose(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting user:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Blog</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this blog?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Blog Comment Details</h5>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                    <tr> 
                                    <th>SL</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Number</th>
                                    <th>Post a comment</th>
                                    <th>Action</th>
                                </tr>
                                    </thead>
                                    <tbody>
                                    {blogComment.map((blog, index) => (
                      <tr key={blog._id}>
                        <td>{index + 1}</td>
                        <td>{blog.name}</td>
                        <td>{blog.email}</td>
                        <td>{blog.website}</td>
                        <td>{blog.comment}</td>
                        <td>
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(blog._id)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogCommentDetails;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import iziToast from 'izitoast';

const Navbar = () => {
  const { apipath,filepath } = useAuth();
  const location = useLocation();
  const [navbarData, setNavbarData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  
  const token = localStorage.getItem('token');
  const userName = localStorage.getItem('userName');
  const userId = localStorage.getItem('userId');
  const userEmail = localStorage.getItem('userEmail');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
    // Check login status on mount
    useEffect(() => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    }, []);

     // Handle redirect based on authentication status
  // const handleRedirect = (path) => {
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     navigate(path);
  //   } else {
  //     localStorage.setItem('redirectAfterLogin', path);
  //     navigate('/signin');
  //   }
  // };
  
  useEffect(() => {
    async function fetchNavbarData() {
      try {
        const response = await axios.get(apipath + '/homePage/details');
        setNavbarData(response.data.homePage[0]);
      } catch (error) {
        console.error('Error fetching home page data:', error);
      }
    }
    fetchNavbarData();
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('adminToken');
    localStorage.removeItem('adminName');
    localStorage.removeItem('adminEmail');
    localStorage.removeItem('adminId');
    
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });

    // navigate('/signin'); // Redirect to login page
    window.location.href = "/";
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light py-3 bg-white sticky-top">
      <div className="container nav-fluid">
        <a className="navbar-brand" href="/">
          {/* <h1>Scrum Training</h1> */}
          {/* <img src={`${process.env.PUBLIC_URL}imgs/logos.jpeg`} width="120" height="80" alt=""/> */}
         { navbarData && (
              <img src={filepath + navbarData.logoImage} width="120" height="80" alt="" />
            )}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {navbarData && (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/')}`} to="/">
                  {navbarData.navbarHome}
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/courses')}`} to="/courses">
                  {isLoggedIn ? "My Courses" : navbarData.navbarCourses}
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/blogs')}`} to="/blogs">
                  {navbarData.navbarBlogs}
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/contact')}`} to="/contact">
                  {navbarData.navbarContactUs}
                </Link>
              </li>
            </ul>
            <div className="d-flex nav-buttons">
            {isLoggedIn ? (
              <div className="d-flex nav-buttons nav-item">
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/imgs/profile1.png`}
                    alt=""
                  />
                </div>
                <h6 className="pt-2">
                  {localStorage.getItem('userName')}
                </h6>
                <i className="bi bi-chevron-down"></i>
                <div className="dropdown-menu">
                  <ul>
                    <li>
                      <Link to={`/profile/${userId}`}>My Profile</Link>
                    </li>
                    <li>
                      <Link to={`/my-booking`}>My Booking</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={handleLogout}>Logout</Link>
                    </li>
                  </ul>
                </div>
              </div>
              ) : ( 
                <Link to="/signin">
                  <button className="first-btn me-3" type="submit">
                    Login →
                  </button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

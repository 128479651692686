import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../../AuthContext';
import axios from 'axios';

const BlogsDetails = () => {
    const { apipath,filepath } = useAuth();
    const [blogs, setBlogs] = useState([]);
    const [deleteBlogId, setDeleteBlogId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBlogs();
        if (!loading && blogs.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, blogs]);

    const handleShow = (id) => {
        setDeleteBlogId(id);
        setShow(true);
    };

    const handleClose = () => {
        setDeleteBlogId(null);
        setShow(false);
    };

    const fetchBlogs = async () => {
        try {
            const response = await axios.get(apipath + '/blog/details');
            setBlogs(response.data.blog);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Blog:', error);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!deleteBlogId) return;

        try {
            setIsLoading(true);
            await axios.delete(apipath + `/blog/details/${deleteBlogId}`);
            await fetchBlogs(); // Refresh users after deletion
            setIsLoading(false);
            handleClose(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting blog:', error);
            setIsLoading(false);
        }
    };

    return (
        <>


            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Blog</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this blog ?</p>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Edit Blogs Details</h5>
                        <div className="add-button">
                            <Link to="/blogAdd"><button>+ Add Blog</button></Link>
                        </div>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Blog Image</th>
                                            <th>Teacher Image</th>
                                            <th>Heading</th>
                                            <th>Sub Heading</th>
                                            <th>TeacherName</th>
                                            <th>Time</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {blogs.map((blog, index) => (
                                            <tr key={blog._id}>
                                                <td>{index + 1}</td>
                                                <td> <img
                                                        src={filepath + blog.blogImage || `${process.env.PUBLIC_URL}/imgs/Agp-Blog-Jan-3 1.png`}
                                                        width={90} height={90}
                                                        style={{ borderRadius: '35px' }}
                                                        // alt={`${blogImages.label?.toLowerCase() || 'default'}-img`}
                                                    /></td>
                                                      <td> <img
                                                        src={filepath + blog.teacherImage || `${process.env.PUBLIC_URL}/imgs/profile.png`}
                                                        width={90} height={90}
                                                        style={{ borderRadius: '35px' }}
                                                        // alt={`${blogImages.label?.toLowerCase() || 'default'}-img`}
                                                    /></td>
                                                <td>{blog.blogHeading}</td>
                                                <td>{blog.blogSubHeading}</td>
                                                <td>{blog.blogTeacherName}</td>
                                                <td>{blog.blogTime}</td>
                                                <td>{blog.blogScheduleMonth}</td>
                                                <td>
                                                    <Link to={`/blogsedit/${blog._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> {" "}
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(blog._id)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BlogsDetails;
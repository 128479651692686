import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import iziToast from "izitoast";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const location = useLocation();
  const { adminLogout } = useAuth();
  const adminToken = localStorage.getItem('adminToken');
  const adminName = localStorage.getItem('adminName');
  const adminEmail = localStorage.getItem('adminEmail');
  const adminId = localStorage.getItem('adminId');
  if(adminToken === null || adminToken === ""){
    navigate("/");
  }

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('adminToken');
    localStorage.removeItem('adminName');
    localStorage.removeItem('adminEmail');
    localStorage.removeItem('adminId');
    
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    
    window.location.href = "/";
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <i className="bi bi-menu-down"></i>
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/main" className="link">
                <i className="bi bi-grid-3x3"></i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/main" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/usersedit" className="link">
                <i className="bi bi-person"></i>
                <span className="name">Users</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/usersedit">Users</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/booking-details" className="link">
              <i class="bi bi-bootstrap"></i>
                <span className="name">Booking Course</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/booking-details">Booking Course</Link>
            </div>
          </li>
          
          
          <li>
            <div className="title">
              <Link to="/enquiry-details" className="link">
              <i class="bi bi-droplet"></i>
                <span className="name">Course Enquiry</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/enquiry-details">Course Enquiry</Link>
            </div>
          </li>
          

          <li>
            <div className="title">
              <Link to="/coursesDetails" className="link">
                <i className="bi bi-bar-chart-steps"></i>
                <span className="name">
                  Courses</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/coursesDetails">
                Courses</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/blogsDetails" className="link">
                <i className="bi bi-bookmark"></i>
                <span className="name">Blogs</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/blogsDetails">Blogs</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/blogComment" className="link">
              <i class="bi bi-substack"></i>
                <span className="name">
                  Blog Comment</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/blogComment">

                Blog Comment</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/contactUs" className="link">
                <i className="bi bi-person"></i>
                <span className="name">
                  Contact Us</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/contactUs">
                Contact Us</Link>
            </div>
          </li>
         
          <li>
            <div className="title">
              <Link to="/banner-images-details" className="link">
              <i class="bi bi-card-image"></i>
                <span className="name">
                  Banner Image</span>
              </Link>
            </div>
            </li>
   
          <li
            className={`dropdown ${activeSubmenu === "general" ? "active" : ""
              }`}
            onClick={() => {
              toggleSubmenu("general");
            }}
          >
            <div className="title">
              <Link className="link">
                <i className="bi bi-gear"></i>
                <span className="name">General Settings</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">General Settings</Link>
              <Link to="/logo" className="link">Logo</Link>
              <Link to="/blog-banner-image" className="link">Blog Banner Image</Link>
              <Link to="/navedit" className="link">Navbar</Link>
              <Link to="/footeredit" className="link">Footer</Link>
            </div>
          </li>

          <li className={`dropdown ${activeSubmenu === "page" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("page");
            }}>
            <div className="title">
              <Link className="link">
              <i class="bi bi-file-earmark"></i>
                <span className="name">Page Settings</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Page Settings</Link>
              <Link to="/homeedit" className="link">Home</Link>
              <Link to="/homeedit" className="link">About</Link>
              <Link to="/homeedit" className="link">Why Us</Link>
            </div>
          </li>

          <li className={`dropdown ${
              activeSubmenu === "footer" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("footer");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-bookshelf"></i>
                <span className="name">Footer Section</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Footer Section</Link>
              <Link to="/footerCedit" className="link">Terms Use</Link>
              <Link to="/footerCedit" className="link">Privacy</Link>
            </div>
          </li>

          <li className={`dropdown ${activeSubmenu === "details" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("details");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-gear"></i>
                <span className="name">
Content Section</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Details Section</Link>
              <Link to="/detailsedit" className="link">Content</Link>
              
              <Link to="/about-payment" className="link">About Payment</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/faq-details" className="link">
              <i class="bi bi-cassette"></i>
                <span className="name">FAQ</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title" to="/faq-details">FAQ</Link>
            </div>
          </li>

          <li className="logout">
            <div className="title">
              <Link className="link">
                <i className="bi bi-box-arrow-left"></i>
                <span className="name" onClick={handleLogout}>Logout</span>
              </Link>
            </div>

          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;

// import React, { useState, useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { useAuth } from "../AuthContext";

// const Blogs = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [blogBannerImage, setBlogBannerImage] = useState([]);
//   const { apipath ,filepath} = useAuth();

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get(apipath + "/blog/details");
//         setBlogs(response.data.blog);
//       } catch (error) {
//         console.error("Error fetching blogs:", error);
//       }
//     };
//     fetchBlogs();
//   }, [apipath]);

//   useEffect(() => {
//     async function fetchBlogBannerImage() {
//         try {
//             const response = await axios.get(apipath + '/homePage/details');
//             setBlogBannerImage(response.data.homePage[0]);
//         } catch (error) {
//             console.error('Error fetching home page data:', error);
//         }
//     }
//     fetchBlogBannerImage();
// }, []);

//   return (
//     <>
//       <Navbar />

//       <div
//         className="container-fluid bd-banner"
//         style={{
//           backgroundImage: blogBannerImage ? `url(${filepath + blogBannerImage.blogBannerImage})` : 'none',
//           backgroundSize: 'contain',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           padding: '8%',
//         }}
//       >
//         <div className="bd-box">
//           <h1>Blog</h1>
//           <p>The proper uѕе оf ѕаfеtу ѕіgnѕ іѕ a соmрulѕоrу requirement.</p>
//           <div class="input-group">
//             <input
//               type="text"
//               class="form-control"
//               placeholder="Blog Search"
//               aria-label="blog search"
//               aria-describedby="basic-addon2"
//             />
//             <div class="input-group-append">
//               <span class="input-group-text" id="basic-addon2">
//                 Search Now
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container b-container">
//       <div className="row">
//         <div className="col-lg-8">
//           {blogs.map((blog) => (
//             <div className="b-left" key={blog._id}>
//               <div className="b-img">
//                 <img src={filepath + blog.blogImage} alt="image not found" width={250} height={350}
//               style={{ borderRadius: '35px' }} />
//               </div>
//               <div className="p-3">
//                 <p>
//                   <i className="bi bi-calendar4-week"></i>{" "}
//                   {blog.blogScheduleMonth}{" "}
//                   <span>
//                     <i className="bi bi-clock"></i> {blog.blogTime}{" "}
//                   </span>
//                 </p>
//                 <h1>{blog.blogHeading}</h1>
//                 <h6>{blog.blogText}</h6>
//                 <div className="b-button">
//                   <Link to={`/blogDetails/${blog._id}`}>
//                     <button>See More</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//           <div className="col-lg-4">
//             <div className="p-3 b-right">
//               <h1>Top Best Posts</h1>
//               <div className="b-box2">
//                 <p>Interdum et malesuada fames ac ante ipsum</p>
//               </div>
//               <div className="b-box2">
//                 <p>Duis aliquet lectus nec faucibus laoreet feugiat</p>
//               </div>
//               <div className="b-box2">
//                 <p>Sed condi mentum nim id luctus tempu que nibh</p>
//               </div>

//               <div className="py-4">
//                 <h1>Categories</h1>

//                 <div className="b-list">
//                   <li>Trending News</li>
//                   <li>Research & Analycis</li>
//                   <li>Case Study</li>
//                   <li>Lawyer Lifestyle</li>
//                 </div>
//               </div>

//               <div className="newsletter">
//                 <h1>Newsletter</h1>
//                 <input type="text" placeholder="Enter email" />
//                 <button>Subscribe Now</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Blogs;

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogBannerImage, setBlogBannerImage] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Step 1: State for search query
  const { apipath, filepath } = useAuth();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(apipath + "/blog/details");
        setBlogs(response.data.blog);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();
  }, [apipath]);

  useEffect(() => {
    async function fetchBlogBannerImage() {
      try {
        const response = await axios.get(apipath + "/homePage/details");
        setBlogBannerImage(response.data.homePage[0]);
      } catch (error) {
        console.error("Error fetching home page data:", error);
      }
    }
    fetchBlogBannerImage();
  }, [apipath]);

  // Step 3: Filter blogs based on search query
  const filteredBlogs = blogs.filter((blog) =>
    blog.blogHeading.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Navbar />

      {/* <div
        className="container-fluid bd-banner"
        style={{
          backgroundImage: blogBannerImage
            ? `url(${filepath + blogBannerImage.blogBannerImage})`
            : "none",
          backgroundSize: "contain",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "8%",
        }}
      >
        <div className="bd-box">
          <h1>Blog</h1>
          <p>The proper uѕе оf ѕаfеtу ѕіgnѕ іѕ a соmрulѕоrу requirement.</p>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Blog Search"
              aria-label="blog search"
              aria-describedby="basic-addon2"
              value={searchQuery} // Step 2: Bind input value to search query state
              onChange={(e) => setSearchQuery(e.target.value)} // Update search query on change
            />
            <div className="input-group-append">
            </div>
          </div>
        </div>
      </div> */}

      <div className="container b-container">
        <div className="row">
          <div className="col-lg-12 blog-grid">
            {filteredBlogs.map((blog) => (
              <div className="b-left" key={blog._id}>
                <div className="b-img">
                  <img
                    src={filepath + blog.blogImage}
                    alt="image not found"
                    width={500}
                    height={400}
                    style={{ borderRadius: "35px" }}
                  />
                </div>
                <div className="p-3">
                  <p>
                    <i className="bi bi-calendar4-week"></i>{" "}
                    {blog.blogScheduleMonth}{" "}
                    <span>
                      <i className="bi bi-clock"></i> {blog.blogTime}{" "}
                    </span>
                  </p>
                  <h1>{blog.blogHeading}</h1>
                  <h6>{blog.blogText}</h6>
                  <div className="b-button">
                    <Link to={`/blogDetails/${blog._id}`}>
                      <button>See More</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="col-lg-4">
            <div className="p-3 b-right">
              <h1>Top Best Posts</h1>
              <div className="b-box2">
                <p>Interdum et malesuada fames ac ante ipsum</p>
              </div>
              <div className="b-box2">
                <p>Duis aliquet lectus nec faucibus laoreet feugiat</p>
              </div>
              <div className="b-box2">
                <p>Sed condi mentum nim id luctus tempu que nibh</p>
              </div>

              <div className="py-4">
                <h1>Categories</h1>

                <div className="b-list">
                  <li>Trending News</li>
                  <li>Research & Analycis</li>
                  <li>Case Study</li>
                  <li>Lawyer Lifestyle</li>
                </div>
              </div>

              <div className="newsletter">
                <h1>Newsletter</h1>
                <input type="text" placeholder="Enter email" />
                <button>Subscribe Now</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blogs;

// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import axios from "axios";
// import { useParams, useNavigate } from "react-router-dom";
// import { useAuth } from "../AuthContext";
// import { Modal } from "react-bootstrap";

// const MyBookingDetails = () => {
//   const [activeTab, setActiveTab] = useState("pending");
  
//   const [show, setShow] = useState(false);
//   const [deleteBookingId, setDeleteBookingId] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const [booking, setBooking] = useState([]);
//   const navigate = useNavigate();
//   const { apipath } = useAuth();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
  
//   const userId = localStorage.getItem('userId');

//   const handleShow = (id) => {
//     setDeleteBookingId(id);
//     setShow(true);
// };

// const handleClose = () => {
//     setDeleteBookingId(null);
//     setShow(false);
// };

//   // Function to check if user is logged in
//   const checkLoginStatus = () => {
//     const token = localStorage.getItem('token');
//     setIsLoggedIn(!!token); // Set login status based on token presence
//   };

//   useEffect(() => {
//     checkLoginStatus(); 
//     fetchBookingDetails();
//   }, []);

//   const fetchBookingDetails = async () => {
//     try {
//       const response = await axios.get(
//         apipath + `/booking/details/user/${userId}`
//       );
//       setBooking(response.data.booking || []);  // Ensure booking is always an array
//       console.log("response.data.booking", response.data.booking);
      
//     } catch (error) {
//       console.error("Error fetching booking details:", error);
//     }
//   };

//   const handleOrderClick = (booking) => {
//     if (booking.bookingStatus === false) { 
//       navigate(`/price/${booking.course_id}`);
//     } 
//   };
  
//   const renderOrderCard = (booking) => (
//     <div 
//       className="col-lg-8 mx-auto order-card" 
//       key={booking._id} 
//       onClick={() => handleOrderClick(booking)}
//       style={{ cursor: booking.bookingStatus === false ? 'pointer' : 'default' }} // Pointer cursor for pending bookings
//     >
//       <div className="order-c">
//         <span>Booking Course</span>
//         <span>₹ {booking.totalPrice}</span>
//       </div>
//       <p className="order-span">Booking ID : <span>{booking.bookingId}</span></p>
//       <p className="order-span">Booking Date : <span>{new Date(booking.bookingDate).toLocaleDateString()}</span></p>
//       <p className="order-span">
//         Booking Status: 
//         <span className={booking.bookingStatus === false ? 'red-text' : ''}>
//           {booking.bookingStatus === false ? 'Pending' : 'Complete'}
//         </span>
//       </p>
  
//       {booking.bookingStatus === false && (
//         <i className="fa-solid fa-trash" onClick={() => handleShow(booking._id)}></i>
//       )}
//     </div>
//   );
  
//   const handleDeleteBooking = async () => {
//     if (!deleteBookingId) return;
//     try {
//         setIsLoading(true);
//         await axios.delete(apipath + `/booking/details/${deleteBookingId}`);
//         await fetchBookingDetails(); // Refresh booking after deletion
//         setIsLoading(false);
//         handleClose(); // Close the modal after deletion
//     } catch (error) {
//         console.error('Error deleting user:', error);
//         setIsLoading(false);
//     }
//   };
  

//   return (
//     <>
//       <Navbar isLoggedIn={isLoggedIn} />


//       <Modal show={show} onHide={handleClose} centered>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Delete Course</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <div className="modal-content1">
//                             <p>Are you sure you want to delete this course?</p>
//                         </div>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <button className="modal-footer-btn" onClick={handleDeleteBooking} disabled={isLoading}>
//                             {isLoading ? "Deleting..." : "Delete"}
//                         </button>
//                         <button className="modal-footer-btn" onClick={handleClose}>
//                             Cancel
//                         </button>
//                     </Modal.Footer>
//                 </Modal>

//       <div className="container">
//         <div className="col-lg-6 mx-auto image-header">
//           <h1>My Booking Courses</h1>
//         </div>
//         <div
//           className={`tab-order-content ${activeTab === "pending" ? "active" : ""}`}
//         >
//           <div className="row order-row pb-5">
//             {booking.length > 0 ? (
//               booking.map(bookings => renderOrderCard(bookings)) // Map over bookings to render each order card
//             ) : (
//               <p>No bookings found.</p> // Show message if no bookings
//             )}
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default MyBookingDetails;

import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { Modal } from "react-bootstrap";

const MyBookingDetails = () => {
  const [activeTab, setActiveTab] = useState("pending");
  const [show, setShow] = useState(false);  // Modal visibility state
  const [deleteBookingId, setDeleteBookingId] = useState(null);  // ID of booking to be deleted
  const [isLoading, setIsLoading] = useState(false);  // Loading state for delete operation
  const [booking, setBooking] = useState([]);  // Array of bookings
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  const handleShow = (id) => {
    setDeleteBookingId(id);  // Set the booking ID for deletion
    setShow(true);  // Show the delete confirmation modal
  };

  const handleClose = () => {
    setDeleteBookingId(null);  // Reset the booking ID
    setShow(false);  // Close the modal
  };

  // Check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);  // Set login status based on token presence
  };

  useEffect(() => {
    checkLoginStatus(); 
    fetchBookingDetails();
  }, []);

  const fetchBookingDetails = async () => {
    try {
      const response = await axios.get(`${apipath}/booking/details/user/${userId}`);
      setBooking(response.data.booking || []);  // Ensure booking is always an array
      console.log("response.data.booking", response.data.booking);
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const handleOrderClick = (booking) => {
    if (booking.bookingStatus === false) { 
      navigate(`/price/${booking.course_id}`);
    }
  };

  const renderOrderCard = (booking) => (
    <div 
      className="col-lg-8 mx-auto order-card" 
      key={booking._id} 
      onClick={() => handleOrderClick(booking)}
      style={{ cursor: booking.bookingStatus === false ? 'pointer' : 'default' }} // Pointer cursor for pending bookings
    >
      <div className="order-c">
        <span>Booking Course</span>
        <span>₹ {booking.totalPrice}</span>
      </div>
      <p className="order-span">Booking ID : <span>{booking.bookingId}</span></p>
      <p className="order-span">Booking Date : <span>{new Date(booking.bookingDate).toLocaleDateString()}</span></p>
      <p className="order-span">
        Booking Status: 
        <span className={booking.bookingStatus === false ? 'red-text' : ''}>
          {booking.bookingStatus === false ? 'Pending' : 'Complete'}
        </span>
      </p>
  
      {/* Show the delete icon only for pending bookings */}
      {booking.bookingStatus === false && (
        <i className="fa-solid fa-trash" onClick={(e) => { e.stopPropagation(); handleShow(booking._id); }}></i>
      )}
    </div>
  );

  const handleDeleteBooking = async () => {
    if (!deleteBookingId) return;
    try {
        setIsLoading(true);
        await axios.delete(`${apipath}/booking/details/${deleteBookingId}`);
        await fetchBookingDetails();  // Refresh booking details after deletion
        setIsLoading(false);
        handleClose();  // Close the modal after deletion
    } catch (error) {
        console.error('Error deleting booking:', error);
        setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />

      {/* Delete Confirmation Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Are you sure you want to delete this booking?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-footer-btn" onClick={handleDeleteBooking} disabled={isLoading}>
            {isLoading ? "Deleting..." : "Delete"}
          </button>
          <button className="modal-footer-btn" onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>My Booking Courses</h1>
        </div>
        <div className={`tab-order-content ${activeTab === "pending" ? "active" : ""}`}>
          <div className="row order-row pb-5">
            {booking.length > 0 ? (
              booking.map(bookings => renderOrderCard(bookings))  // Render booking cards
            ) : (
              <p>No bookings found.</p>  // Show message if no bookings
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MyBookingDetails;

import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import iziToast from "izitoast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axios from "axios";

const LogoEdit = () => {
    const [imageUrl, setImageUrl] = useState(null);
    const [formData, setFormData] = useState({
        logoImage: null,
    });

    const navigate = useNavigate();
    const { apipath, filepath } = useAuth();
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchBannerEdit();
    }, []);

    const fetchBannerEdit = async () => {
        try {
            const response = await axios.get(
                `${apipath}/homePage/details/66a3c8240788bc05987fc657`
            );

            if (response.data && response.data.homePage) {
                setImageUrl(filepath + response.data.homePage.logoImage);
            }

            setFormData({
                ...formData,
                logoImage: response.data.homePage.logoImage,
            });

        } catch (error) {
            console.error("Error fetching banner image details:", error);
        }
    };

    const handleUploadButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                logoImage: file,
            });
            setImageUrl(URL.createObjectURL(file));
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const data = new FormData();
        if (formData.logoImage) {
            data.append('logoImage', formData.logoImage);
        }

        try {
            const response = await axios.put(`${apipath}/homePage/details/66a3c8240788bc05987fc657`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                iziToast.success({
                    message: 'Logo image updated successfully',
                    position: 'topCenter',
                });
                navigate('/logo');
            } else {
                iziToast.error({
                    message: 'Logo image update failed',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error('Error updating banner image:', error);
            iziToast.error({
                message: 'An error occurred',
                position: 'topCenter',
            });
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="Container ">
                    <div className="row">
                        <div className="col-lg-5">
                            <form className="row" onSubmit={handleUpdate}>
                                <div className="logo-insidebox">
                                    <div>
                                        <h5>Existing Logo</h5>
                                        {imageUrl && (
                                            <img
                                                src={imageUrl}
                                                alt="Preview"
                                                width={200} height={100}
                                                style={{ borderRadius: '35px' }}
                                                className="mt-3"
                                            />
                                        )}
                                    </div>
                                    <div className="pt-5">
                                        <h5>Change Logo</h5>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            name="logoImage"
                                            onChange={handleChange}
                                            className="pt-3"
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-success mt-3">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LogoEdit;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../AuthContext";

const Footer = ({ classes }) => {
  const { apipath } = useAuth();
  const [footerData, setFooterData] = useState(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  useEffect(() => {
      async function fetchFooterData() {
          try {
              const response = await axios.get(apipath + '/homePage/details');
              setFooterData(response.data.homePage[0]);
          } catch (error) {
              console.error('Error fetching home page data:', error);
          }
      }
      fetchFooterData();
  }, []);

  return (
    <div
      className="container-fluid footer-c py-4"
      style={classes ? {marginTop: "0%"} : {marginTop: "5%"}}
    >
     {footerData && (
          <>
      <div className="footer-list container">
        <div>
          <Link className="footer-link me-4" to="/privacy">
          {footerData.footerTermsConditions}
          </Link>
          <Link className="footer-link" to="/terms">
          {footerData.footerPrivacyPolicy}
          </Link>
        </div>
        <h1 className="footer my-auto">{footerData.footerCopyright}</h1>
      </div>
      </>
      )}
    </div>
  );
};

Footer.defaultProps = {
  classes: false,
};


export default Footer;

// import React, { useState } from "react";
// import SideBar from "./SideBar";
// import Navbar from "./Navbar";
// import { useNavigate } from "react-router-dom";
// import iziToast from "izitoast";
// import { useAuth } from "../../AuthContext";
// import axios from "axios";
// import { Link } from "react-bootstrap-icons";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


// const BlogAdd = () => {
//     const [blogImageUrl, setBlogImageUrl] = useState(null);
//     const [blogContents, setBlogContents] = useState('');

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         const name = event.target.name;
//         if (file) {
//             setFormData((prevDetails) => ({
//                 ...prevDetails,
//                 [name]: file,
//             }));
//             const reader = new FileReader();
//             reader.onload = (e) => {
//                 if (name === "blogImageUrl") setBlogImageUrl(e.target.result);
//             };
//             reader.readAsDataURL(file);
//         }
//     };

//     const navigate = useNavigate();
//     const { apipath, filepath, logout } = useAuth();
//     const [formData, setFormData] = useState({
//         blogHeading: '',
//         blogText: '',
//         blogContent: '',
//         blogTeacherName: '',
//         blogTime: '',
//         blogScheduleMonth: '',
//         blogImage: null,
//     });

//     const token = localStorage.getItem('token');

//     const handleUploadButtonClick = () => {
//         document.getElementById('fileInput').click();
//     };

//     const handleChange = (e) => {
//         const { name, value, type } = e.target;
//         if (type === 'file') {
//             const file = e.target.files[0];
//             setFormData({
//                 ...formData,
//                 [name]: file,
//                 blogImage: URL.createObjectURL(file), // Set the preview URL
//             });
//         } else {
//             setFormData({
//                 ...formData,
//                 [name]: value,
//             });
//         }
//     };

//     const handleRegister = async (e) => {
//         e.preventDefault();
    
//         const formdata = new FormData();
//         formdata.append('blogHeading', formData.blogHeading);
//         formdata.append('blogText', formData.blogText);
//         formdata.append('blogContent', formData.blogContent);  // Make sure this matches the API expectation
//         formdata.append('blogTeacherName', formData.blogTeacherName);
//         formdata.append('blogTime', formData.blogTime);
//         formdata.append('blogScheduleMonth', formData.blogScheduleMonth);
    
//         // Check if blogImage is selected
//         if (formData.blogImage) {
//             formdata.append('blogImage', formData.blogImage);  // Append the image file
//         }
    
//         try {
//             const response = await axios.post('http://localhost:5000/api/v1/blog/add',
//                 formdata,
//                 {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                         'Authorization': `Bearer ${token}`  // Include token if authentication is required
//                     },
//                 }
//             );
    
//             if (response.status === 201) {
//                 iziToast.success({
//                     message: 'Blog added successfully',
//                     position: 'topCenter',
//                 });
//                 navigate('/blogsDetails');
//             } else {
//                 iziToast.error({
//                     message: 'Blog addition failed',
//                     position: 'topCenter',
//                 });
//             }
//         } catch (error) {
//             console.error('Error adding blog:', error);
//             iziToast.error({
//                 message: 'An error occurred',
//                 position: 'topCenter',
//             });
//         }
//     };
    
//     const handleEditorChange = (event, editor) => {
//         const data = editor.getData();
//         setBlogContents(data);
//         setFormData(prevDetails => ({
//             ...prevDetails,
//             blogContent: data,
//         }));
//     }

//     return (
//         <>
//             <SideBar />
//             <Navbar />

//             <section className="home">
//                 <div className="toggle-sidebar" style={{ display: "none" }}>
//                     <i className="bi bi-menu"></i>
//                 </div>

//                 <div class="container pt-3">
//                     <div class="row top-barcolumn">
//                         <h5>Add Blog Information</h5>
//                         <div class="row nav-column">
//                             <div>
//                                 <form className="row" onSubmit={handleRegister}>
//                                     <div class="col-lg-12 mb-3">
//                                         <label for="nav-items1" class="form-label">
//                                             Blog Heading
//                                         </label>
//                                         <textarea
//                                             type="text"
//                                             class="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog heading"
//                                             name="blogHeading"
//                                             value={formData.blogHeading}
//                                             onChange={handleChange}
//                                             maxLength="100"
//                                             required
//                                         />
//                                     </div>
//                                     <div class="col-lg-12 mb-3">
//                                         <label for="nav-items2" class="form-label">
//                                             Blog Text
//                                         </label>
//                                         <textarea
//                                             type="text"
//                                             class="form-control"
//                                             id="nav-item2"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog text"
//                                             name="blogText"
//                                             value={formData.blogText}
//                                             onChange={handleChange}
//                                             maxLength="500"
//                                             required
//                                         ></textarea>
//                                     </div>
//                                     <div className="col-lg-12 mb-3">
//                                         <label for="nav-items1" className="form-label">
//                                             Blog Teacher Name
//                                         </label>
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog blog teacher name"
//                                             name="blogTeacherName"
//                                             value={formData.blogTeacherName}
//                                             onChange={handleChange}
//                                             maxLength="50"
//                                             required
//                                         />
//                                     </div>
//                                     <div className="col-lg-12 mb-3">
//                                         <label for="nav-items1" className="form-label">
//                                             Blog Time
//                                         </label>
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog time 2:30pm"
//                                             name="blogTime"
//                                             value={formData.blogTime}
//                                             onChange={handleChange}
//                                             maxLength="50"
//                                             required
//                                         />
//                                     </div>

//                                     <div class="col-lg-12 mb-3">
//                                         <label for="nav-items2" class="form-label">
//                                             Blog Schedule Month
//                                         </label>
//                                         <input
//                                             type="text"
//                                             class="form-control"
//                                             id="nav-item2"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog schedule month 02 June 2024"
//                                             name="blogScheduleMonth"
//                                             value={formData.blogScheduleMonth}
//                                             onChange={handleChange}
//                                             maxLength="50"
//                                             required
//                                         />
//                                     </div>

//                                     <div className="col-lg-12 mb-3">
//                                         <label for="nav-items2" class="form-label"></label>
//                                         <CKEditor
//                                             editor={ClassicEditor}
//                                             data={blogContents}
//                                             onChange={handleEditorChange}
//                                             name="blogContent"
//                                         />
//                                     </div>

//                                     <div className="change-profile pt-4 pb-4">
//                                         <img
//                                             src={blogImageUrl ? URL.createObjectURL(formData.blogImage) : `${process.env.PUBLIC_URL}/imgs/imagess.png`}
//                                             width={150} height={100}
//                                             style={{ borderRadius: '35px' }}
//                                             alt="profile-img"
//                                             onClick={handleUploadButtonClick}
//                                         />
//                                         <h6>Product Image</h6>
//                                         <input
//                                             className="form-control mt-4"
//                                             type="file"
//                                             id="fileInput"
//                                             name="blogImage"
//                                             onChange={handleFileChange}
//                                         />
//                                     </div>

//                                     <div className="">
//                                         <button type="submit" className="btn btn-success">
//                                             Add Blog
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default BlogAdd;


import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const BlogAdd = () => {
    const [blogContents, setBlogContents] = useState('');

    const navigate = useNavigate();
    const { apipath } = useAuth();
    const [blogImageUrl, setBlogImageUrl] = useState(null);
    const [teacherImageUrl, setTeacherImageUrl] = useState(null);
    const [formData, setFormData] = useState({
        blogHeading: '',
        blogText: '',
        blogContent: '',
        blogTeacherName: '',
        blogTime: '',
        blogScheduleMonth: '',
        blogImage: null,
        teacherImage: null,
    });

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);

            if (type === 'blogImage') {
                setFormData((prevDetails) => ({
                    ...prevDetails,
                    blogImage: file,
                }));
                setBlogImageUrl(previewUrl);
            } else if (type === 'teacherImage') {
                setFormData((prevDetails) => ({
                    ...prevDetails,
                    teacherImage: file,
                }));
                setTeacherImageUrl(previewUrl);
            }
        }
    };
    const token = localStorage.getItem('token');

    const handleUploadButtonClick = () => {
        document.getElementById('fileInput').click();
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const formdata = new FormData();
        formdata.append('blogHeading', formData.blogHeading);
        formdata.append('blogText', formData.blogText);
        formdata.append('blogContent', formData.blogContent);
        formdata.append('blogTeacherName', formData.blogTeacherName);
        formdata.append('blogTime', formData.blogTime);
        formdata.append('blogScheduleMonth', formData.blogScheduleMonth);
        formdata.append('blogImage', formData.blogImage);
        formdata.append('teacherImage', formData.teacherImage);

        try {
            const response = await axios.post(apipath + '/blog/add',
                formdata,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 201) {
                iziToast.success({
                    message: 'Blog added successfully',
                    position: 'topCenter',
                });
                navigate('/blogsDetails');
            } else {
                iziToast.error({
                    message: 'Blog addition failed',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error('Error adding blog:', error);
            iziToast.error({
                message: 'An error occurred',
                position: 'topCenter',
            });
        }
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setBlogContents(data);
        setFormData(prevDetails => ({
            ...prevDetails,
            blogContent: data,
        }));
    }

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <h5>Add Blog Information</h5>
                        <div className="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleRegister}>
                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items1" className="form-label">
                                            Blog Heading
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter blog heading"
                                            name="blogHeading"
                                            value={formData.blogHeading}
                                            onChange={handleChange}
                                            maxLength="100"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items2" className="form-label">
                                            Blog Text
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter blog text"
                                            name="blogText"
                                            value={formData.blogText}
                                            onChange={handleChange}
                                            maxLength="500"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items1" className="form-label">
                                            Blog Teacher Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter blog teacher name"
                                            name="blogTeacherName"
                                            value={formData.blogTeacherName}
                                            onChange={handleChange}
                                            maxLength="50"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items1" className="form-label">
                                            Blog Time
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter blog time 2:30pm"
                                            name="blogTime"
                                            value={formData.blogTime}
                                            onChange={handleChange}
                                            maxLength="50"
                                            required
                                        />
                                    </div>

                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items2" className="form-label">
                                            Blog Schedule Month
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter blog schedule month 02 June 2024"
                                            name="blogScheduleMonth"
                                            value={formData.blogScheduleMonth}
                                            onChange={handleChange}
                                            maxLength="50"
                                            required
                                        />
                                    </div>

                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items2" className="form-label"></label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={blogContents}
                                            onChange={handleEditorChange}
                                            name="blogContent"
                                        />
                                    </div>

                                    <div className="change-profile pt-4 pb-4">
                <img
                    src={blogImageUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
                    width={150} height={100}
                    style={{ borderRadius: '35px' }}
                    alt="profile-img"
                    onClick={() => document.getElementById('blogImageInput').click()}
                />
                <h6>Blog Image</h6>
                <input
                    className="form-control mt-4"
                    type="file"
                    id="blogImageInput"
                    name="blogImage"
                    onChange={(e) => handleFileChange(e, 'blogImage')}
                    // style={{ display: 'none' }}
                />
            </div>

            <div className="change-profile pt-4 pb-4">
                <img
                    src={teacherImageUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
                    width={150} height={100}
                    style={{ borderRadius: '35px' }}
                    alt="profile-img"
                    onClick={() => document.getElementById('teacherImageInput').click()}
                />
                <h6>Blog Teacher Image</h6>
                <input
                    className="form-control mt-4"
                    type="file"
                    id="teacherImageInput"
                    name="teacherImage"
                    onChange={(e) => handleFileChange(e, 'teacherImage')}
                    // style={{ display: 'none' }}
                />
            </div>
                                    <div>
                                        <button type="submit" className="btn btn-success">
                                            Add Blog
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogAdd;

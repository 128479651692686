// // // import React, { useState, useEffect } from "react";
// // // import axios from "axios";
// // // import { useAuth } from "../../AuthContext";
// // // import iziToast from "izitoast";
// // // import SideBar from "./SideBar";
// // // import Navbar from "./Navbar";
// // // import { CKEditor } from '@ckeditor/ckeditor5-react';
// // // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// // // const HomeEdit = () => {
// // //   const { apipath,filepath } = useAuth();
// // //   const [aboutImageUrl, setAboutImageUrl] = useState(null);
// // //   const [whyUsImageUrl, setWhyUsImageUrl] = useState(null);
// // //   const [activeTab, setActiveTab] = useState("banner");

// // //   const openTab = (tabName) => {
// // //     setActiveTab(tabName);
// // //   };


// // //   const handleFileChange = (event) => {
// // //     const file = event.target.files[0];
// // //     const name = event.target.name;
    
// // //     if (file) {
// // //       setHomePageDetails((prevDetails) => ({
// // //         ...prevDetails,
// // //         [name]: file,
// // //       }));
      
// // //       const reader = new FileReader();
// // //       reader.onload = (e) => {
// // //         setAboutImageUrl(e.target.result);
// // //         setWhyUsImageUrl(e.target.result);
// // //       };
// // //       reader.readAsDataURL(file);
// // //     }
// // //   };
  

// // //   const [homePageDetails, setHomePageDetails] = useState({
// // //     courseHeading: "",
// // //     courseSubHeading: "",
// // //     courseText: "",
// // //     aboutHeading: "",
// // //     aboutText1: "",
// // //     aboutText2: "",
// // //     whyUsText:"",
// // //     whyUsImage:null,
// // //     aboutImage:null
// // //   });

// // //   useEffect(() => {
// // //     fetchHomePageDetails();
// // //   }, []);

// // //   const fetchHomePageDetails = async () => {
// // //     try {
// // //       const response = await axios.get(
// // //         `${apipath}/homePage/details/66d6e7098036cd592b09b027`
// // //       );
      
// // //       if (response.data && response.data.homePage) {
// // //         setAboutImageUrl(filepath + response.data.homePage.aboutImage);
// // //     }

// // //     if (response.data && response.data.homePage) {
// // //       setWhyUsImageUrl(filepath + response.data.homePage.whyUsImage);
// // //   }
// // //       setHomePageDetails(response.data.homePage);
// // //     } catch (error) {
// // //       console.error("Error fetching home page details:", error);
// // //     }
// // //   };



// // //   const handleUpdate = async (e) => {
// // //     e.preventDefault();
    
// // //     // Create FormData object to handle file upload and text data
// // //     const formData = new FormData();
// // //     formData.append("courseHeading", homePageDetails.courseHeading);
// // //     formData.append("courseSubHeading", homePageDetails.courseSubHeading);
// // //     formData.append("courseText", homePageDetails.courseText);
// // //     formData.append("aboutHeading", homePageDetails.aboutHeading);
// // //     formData.append("aboutText1", homePageDetails.aboutText1);
// // //     formData.append("aboutText2", homePageDetails.aboutText2);
// // //     formData.append("whyUsText", homePageDetails.whyUsText);

// // //     // Append images if they exist
// // //     if (homePageDetails.aboutImage) {
// // //         formData.append("aboutImage", homePageDetails.aboutImage);
// // //         console.log("homePageDetails.aboutImage : ",homePageDetails.aboutImage);
        
// // //     }
// // //     if (homePageDetails.whyUsImage) {
// // //         formData.append("whyUsImage", homePageDetails.whyUsImage);
// // //     }

// // //     try {
// // //       const response = await axios.put(
// // //         `${apipath}/homePage/details/66d6e7098036cd592b09b027`,
// // //         formData,
// // //         {
// // //           headers: {
// // //             "Content-Type": "multipart/form-data",
// // //           },
// // //         }
// // //       );

// // //       if (response.status === 200) {
// // //         iziToast.success({
// // //           message: "Home page details updated successfully!",
// // //           position: "topCenter",
// // //         });
// // //       } else {
// // //         iziToast.error({
// // //           message: "Home page details update failed",
// // //           position: "topCenter",
// // //         });
// // //       }
// // //     } catch (error) {
// // //       console.error("Error updating home page details:", error);
// // //       iziToast.error({
// // //         message: "An error occurred",
// // //         position: "topCenter",
// // //       });
// // //     }
// // //   };


// // //   const handleChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setHomePageDetails((prevDetails) => ({
// // //       ...prevDetails,
// // //       [name]: value,
// // //     }));
// // //   };

// // //   const handleEditorChange = (event, editor) => {
// // //     const data = editor.getData();
// // //     const name = editor.sourceElement.getAttribute("name");
    
// // //     setHomePageDetails((prevDetails) => ({
// // //       ...prevDetails,
// // //       [name]: data,
// // //     }));
// // //   };
  
// // //   return (
// // //     <>
// // //      <SideBar />
// // //      <Navbar />

// // //       <section className="home">
// // //         <div className="toggle-sidebar" style={{ display: "none" }}>
// // //           <i className="bi bi-menu"></i>
// // //         </div>

// // //         <div className="container pt-3">
// // //           <div className="row home-toggletab">
// // //             <h4>Edit Homepage Informtion</h4>
// // //             <div className="col-lg-11 hometab-column">
              
// // //               <div className="tab">
// // //                 <button
// // //                   className={`tablinks  ${activeTab === "banner" && "active"}`}
// // //                   onClick={() => openTab("banner")}
// // //                   id="banner-button"
// // //                 >
// // //                   Main Banner
// // //                 </button>
// // //                 <button
// // //                   className={`tablinks ${activeTab === "about" && "active"}`}
// // //                   onClick={() => openTab("about")}
// // //                   id="about-button"
// // //                 >
// // //                   About Instructor
// // //                 </button>
// // //                 <button
// // //                   className={`tablinks ${activeTab === "training" && "active"}`}
// // //                   onClick={() => openTab("training")}
// // //                   id="training-button"
// // //                 >
// // //                   Why Us
// // //                 </button>
// // //               </div>

// // //               <div
// // //                 id="banner"
// // //                 className={`tabcontent ${
// // //                   activeTab === "banner" ? "active" : ""
// // //                 }`}
// // //               >
// // //                 <div className="season_content">
// // //                   <form>
// // //                     <div className="mb-4">
// // //                       <h5>Title</h5>
// // //                       <input
// // //                         type="text"
// // //                         className="form-control"
// // //                         name="courseHeading"
// // //                         value={homePageDetails.courseHeading}
// // //                         onChange={handleChange}
// // //                       />
// // //                     </div>
// // //                     <div className="mb-4">
// // //                       <h5>Subtitle</h5>
// // //                       <input
// // //                         type="text"
// // //                         className="form-control"
// // //                         name="courseSubHeading"
// // //                         value={homePageDetails.courseSubHeading}
// // //                         onChange={handleChange}
// // //                       />
// // //                     </div>
// // //                     <div className="mb-4">
// // //                       <h5>Content</h5>
// // //                       <textarea
// // //                         type="text"
// // //                         className="form-control"
// // //                         name="courseText"
// // //                         value={homePageDetails.courseText}
// // //                         onChange={handleChange}
// // //                       />
// // //                     </div>
// // //                   </form>
// // //                   <button
// // //                     type="button"
// // //                     className="btn btn-success"
// // //                     onClick={handleUpdate}
// // //                   >
// // //                     Update
// // //                   </button>
// // //                 </div>
// // //               </div>

// // //               <div
// // //                 id="about"
// // //                 className={`tabcontent ${
// // //                   activeTab === "about" ? "active" : ""
// // //                 }`}
// // //               >
// // //                 <div className="season_content">
// // //                   <form>
// // //                     <div className="mb-4">
// // //                       <h5>Title</h5>
// // //                       <input
// // //                         type="text"
// // //                         className="form-control"
// // //                         id="title"
// // //                         aria-describedby="title"
// // //                         placeholder="About Meiyappan Muthu"
// // //                         name="aboutHeading"
// // //                         value={homePageDetails.aboutHeading}
// // //                         onChange={handleChange}
// // //                       />
// // //                     </div>
// // //                     <div className="mb-4">
// // //                       <h5>Content1</h5>
// // //                       <CKEditor
// // //   editor={ClassicEditor}
// // //   data={homePageDetails.aboutText1}
// // //   onChange={handleEditorChange}
// // //   name="aboutText1"
// // // />
// // //                       {/* <textarea
// // //                         type="text"
// // //                         className="form-control"
// // //                         id="title"
// // //                         aria-describedby="title"
// // //                         placeholder="Content"
// // //                         name="aboutText1"
// // //                         value={homePageDetails.aboutText1}
// // //                         onChange={handleChange}
// // //                       /> */}
// // //                     </div>
                    
// // //                     {/* <div className="mb-4">
// // //                       <h5>Content2</h5>
// // //                       <textarea
// // //                         type="text"
// // //                         className="form-control"
// // //                         id="exampleInputEmail1"
// // //                         aria-describedby="emailHelp"
// // //                         placeholder="Content"
// // //                         name="aboutText2"
// // //                         value={homePageDetails.aboutText2}
// // //                         onChange={handleChange}
// // //                       />
// // //                     </div> */}
// // //                     <div className="mb-4">
// // //                       <h5>Image</h5>
// // //                       <input
// // //                         type="file" 
// // //                         id="fileInput"
// // //                         name="aboutImage"
// // //                         onchange={handleFileChange}
// // //                         className="pt-3"
// // //                       />
// // //                       {aboutImageUrl && (
// // //                         <img
// // //                           src={aboutImageUrl}
// // //                           alt="Preview"
// // //                           style={{ width: "100px", height: "100px" }}
// // //                         />
// // //                       )}
// // //                     </div>
// // //                     <button
// // //                     type="button"
// // //                     className="btn btn-success"
// // //                     onClick={handleUpdate}
// // //                   >
// // //                     Update
// // //                   </button>
// // //                   </form>
// // //                 </div>
// // //               </div>
// // //               <div
// // //                 id="training"
// // //                 className={`tabcontent ${
// // //                   activeTab === "training" ? "active" : ""
// // //                 }`}
// // //               >
// // //                 <div className="season_content">
// // //                   <form>
// // //                     <div className="mb-4">
// // //                       <h5>Why Us Content</h5>
// // //                       <CKEditor
// // //   editor={ClassicEditor}
// // //   data={homePageDetails.whyUsText}
// // //   onChange={handleEditorChange}
// // //   name="whyUsText"
// // // />
// // //                     </div>
// // //                     <div className="mb-4">
// // //                       <h5>Why Us Image</h5>
// // //                       <input
// // //   type="file" 
// // //   id="fileInput"
// // //   name="whyUsImage"
// // //   onChange={handleFileChange}
// // //   className="pt-3"
// // // />
// // // {whyUsImageUrl && (
// // //   <img
// // //     src={whyUsImageUrl}
// // //     alt="Preview"
// // //     style={{ width: "100px", height: "100px" }}
// // //   />
// // // )}

// // //                     </div>
                   
// // //                     <button
// // //                     type="button"
// // //                     className="btn btn-success"
// // //                     onClick={handleUpdate}
// // //                   >
// // //                     Update
// // //                   </button>
// // //                   </form>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </section>
// // //     </>
// // //   );
// // // };

// // // export default HomeEdit;


// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { useAuth } from "../../AuthContext";
// // import iziToast from "izitoast";
// // import SideBar from "./SideBar";
// // import Navbar from "./Navbar";
// // import { CKEditor } from '@ckeditor/ckeditor5-react';
// // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// // const HomeEdit = () => {
// //   const { apipath, filepath } = useAuth();
// //   const [aboutImageUrl, setAboutImageUrl] = useState(null);
// //   const [whyUsImageUrl, setWhyUsImageUrl] = useState(null);
// //   const [activeTab, setActiveTab] = useState("banner");

// //   const openTab = (tabName) => {
// //     setActiveTab(tabName);
// //   };

// //   const handleFileChange = (event) => {
// //     const file = event.target.files[0];
// //     const name = event.target.name;
    
// //     if (file) {
// //       setHomePageDetails((prevDetails) => ({
// //         ...prevDetails,
// //         [name]: file,
// //       }));

// //       const reader = new FileReader();
// //       reader.onload = (e) => {
// //         if (name === "aboutImage") setAboutImageUrl(e.target.result);
// //         if (name === "whyUsImage") setWhyUsImageUrl(e.target.result);
// //       };
// //       reader.readAsDataURL(file);
// //     }
// //   };

// //   const [homePageDetails, setHomePageDetails] = useState({
// //     courseHeading: "",
// //     courseSubHeading: "",
// //     courseText: "",
// //     aboutHeading: "",
// //     aboutText1: "",
// //     whyUsText:"",
// //     whyUsImage:null,
// //     aboutImage:null
// //   });

// //   useEffect(() => {
// //     fetchHomePageDetails();
// //   }, []);

// //   const fetchHomePageDetails = async () => {
// //     try {
// //       const response = await axios.get(
// //         `${apipath}/homePage/details/66d6e7098036cd592b09b027`
// //       );

// //       if (response.data && response.data.homePage) {
// //         setAboutImageUrl(filepath + response.data.homePage.aboutImage);
// //         setWhyUsImageUrl(filepath + response.data.homePage.whyUsImage);
// //         setHomePageDetails(response.data.homePage);
// //       }
// //     } catch (error) {
// //       console.error("Error fetching home page details:", error);
// //     }
// //   };

// //   const handleUpdate = async (e) => {
// //     e.preventDefault();
    
// //     const formData = new FormData();
// //     formData.append("courseHeading", homePageDetails.courseHeading);
// //     formData.append("courseSubHeading", homePageDetails.courseSubHeading);
// //     formData.append("courseText", homePageDetails.courseText);
// //     formData.append("aboutHeading", homePageDetails.aboutHeading);
// //     formData.append("aboutText1", homePageDetails.aboutText1);
// //     formData.append("aboutText2", homePageDetails.aboutText2);
// //     formData.append("whyUsText", homePageDetails.whyUsText);

// //     if (homePageDetails.aboutImage) {
// //       formData.append("aboutImage", homePageDetails.aboutImage);
// //     }
// //     if (homePageDetails.whyUsImage) {
// //       formData.append("whyUsImage", homePageDetails.whyUsImage);
// //     }

// //     try {
// //       for (let [key, value] of formData.entries()) {
// //         console.log(`${key}:`, value);
// //       }

// //       const response = await axios.put(
// //         `${apipath}/homePage/details/66d6e7098036cd592b09b027`,
// //         formData,
// //         {
// //           headers: {
// //             "Content-Type": "multipart/form-data",
// //           },
// //         }
// //       );

// //       if (response.status === 200) {
// //         iziToast.success({
// //           message: "Home page details updated successfully!",
// //           position: "topCenter",
// //         });
// //       } else {
// //         iziToast.error({
// //           message: "Home page details update failed",
// //           position: "topCenter",
// //         });
// //       }
// //     } catch (error) {
// //       console.error("Error updating home page details:", error);
// //       iziToast.error({
// //         message: "An error occurred",
// //         position: "topCenter",
// //       });
// //     }
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setHomePageDetails((prevDetails) => ({
// //       ...prevDetails,
// //       [name]: value,
// //     }));
// //   };

// //   const handleEditorChange = (event, editor) => {
// //     const data = editor.getData();
// //     const name = editor.sourceElement.getAttribute("name");

// //     setHomePageDetails((prevDetails) => ({
// //       ...prevDetails,
// //       [name]: data,
// //     }));
// //   };

// //   return (
// //     <>
// //      <SideBar />
// //      <Navbar />

// //       <section className="home">
// //         <div className="toggle-sidebar" style={{ display: "none" }}>
// //           <i className="bi bi-menu"></i>
// //         </div>

// //         <div className="container pt-3">
// //           <div className="row home-toggletab">
// //             <h4>Edit Homepage Information</h4>
// //             <div className="col-lg-11 hometab-column">
              
// //               <div className="tab">
// //                 <button
// //                   className={`tablinks  ${activeTab === "banner" && "active"}`}
// //                   onClick={() => openTab("banner")}
// //                   id="banner-button"
// //                 >
// //                   Main Banner
// //                 </button>
// //                 <button
// //                   className={`tablinks ${activeTab === "about" && "active"}`}
// //                   onClick={() => openTab("about")}
// //                   id="about-button"
// //                 >
// //                   About Instructor
// //                 </button>
// //                 <button
// //                   className={`tablinks ${activeTab === "training" && "active"}`}
// //                   onClick={() => openTab("training")}
// //                   id="training-button"
// //                 >
// //                   Why Us
// //                 </button>
// //               </div>

// //               <div
// //                 id="banner"
// //                 className={`tabcontent ${
// //                   activeTab === "banner" ? "active" : ""
// //                 }`}
// //               >
// //                 <div className="season_content">
// //                   <form>
// //                     <div className="mb-4">
// //                       <h5>Title</h5>
// //                       <input
// //                         type="text"
// //                         className="form-control"
// //                         name="courseHeading"
// //                         value={homePageDetails.courseHeading}
// //                         onChange={handleChange}
// //                       />
// //                     </div>
// //                     <div className="mb-4">
// //                       <h5>Subtitle</h5>
// //                       <input
// //                         type="text"
// //                         className="form-control"
// //                         name="courseSubHeading"
// //                         value={homePageDetails.courseSubHeading}
// //                         onChange={handleChange}
// //                       />
// //                     </div>
// //                     <div className="mb-4">
// //                       <h5>Content</h5>
// //                       <textarea
// //                         type="text"
// //                         className="form-control"
// //                         name="courseText"
// //                         value={homePageDetails.courseText}
// //                         onChange={handleChange}
// //                       />
// //                     </div>
// //                   </form>
// //                   <button
// //                     type="button"
// //                     className="btn btn-success"
// //                     onClick={handleUpdate}
// //                   >
// //                     Update
// //                   </button>
// //                 </div>
// //               </div>

// //               <div
// //                 id="about"
// //                 className={`tabcontent ${
// //                   activeTab === "about" ? "active" : ""
// //                 }`}
// //               >
// //                 <div className="season_content">
// //                   <form>
// //                     <div className="mb-4">
// //                       <h5>Title</h5>
// //                       <input
// //                         type="text"
// //                         className="form-control"
// //                         id="title"
// //                         aria-describedby="title"
// //                         placeholder="About Meiyappan Muthu"
// //                         name="aboutHeading"
// //                         value={homePageDetails.aboutHeading}
// //                         onChange={handleChange}
// //                       />
// //                     </div>
// //                     <div className="mb-4">
// //                       <h5>Content</h5>
// //                       <CKEditor
// //                         editor={ClassicEditor}
// //                         data={homePageDetails.aboutText1}
// //                         onChange={handleEditorChange}
// //                         name="aboutText1"
// //                       />
// //                     </div>

// //                     <div className="row g-3">
// //                       <div className="col-md-6 mb-4">
// //                         <h5>Image</h5>
// //                         <div className="imgbox">
// //                           <img
// //                             src={aboutImageUrl}
// //                             id="imgPreview"
// //                             alt="About"
// //                             width={250} height={200}
// //                                                 style={{ borderRadius: '35px' }}
// //                                                 className="mt-3"
// //                           />
// //                         </div>
// //                         <input
// //                           className="form-control mt-4"
// //                           type="file"
// //                           id="formFile"
// //                           name="aboutImage"
// //                           onChange={handleFileChange}
// //                         />
// //                       </div>
// //                     </div>
// //                   </form>
// //                   <button
// //                     type="button"
// //                     className="btn btn-success"
// //                     onClick={handleUpdate}
// //                   >
// //                     Update
// //                   </button>
// //                 </div>
// //               </div>

// //               <div
// //                 id="training"
// //                 className={`tabcontent ${
// //                   activeTab === "training" ? "active" : ""
// //                 }`}
// //               >
// //                 <div className="season_content">
// //                   <form>
// //                     <div className="mb-4">
// //                       <h5>Text</h5>
// //                       <CKEditor
// //                         editor={ClassicEditor}
// //                         data={homePageDetails.whyUsText}
// //                         onChange={handleEditorChange}
// //                         name="whyUsText"
// //                       />
// //                     </div>

// //                     <div className="row g-3">
// //                       <div className="col-md-6 mb-4">
// //                         <h5>Image</h5>
// //                         <div className="imgbox">
// //                           <img
// //                             src={whyUsImageUrl}
// //                             id="imgPreview"
// //                             alt="Why Us"
// //                             width={250} height={200}
// //                                                 style={{ borderRadius: '35px' }}
// //                                                 className="mt-3"
// //                           />
// //                         </div>
// //                         <input
// //                           className="form-control mt-4"
// //                           type="file"
// //                           id="formFile"
// //                           name="whyUsImage"
// //                           onChange={handleFileChange}
// //                         />
// //                       </div>
// //                     </div>
// //                   </form>
// //                   <button
// //                     type="button"
// //                     className="btn btn-success"
// //                     onClick={handleUpdate}
// //                   >
// //                     Update
// //                   </button>
// //                 </div>
// //               </div>

// //             </div>
// //           </div>
// //         </div>
// //       </section>
// //     </>
// //   );
// // };

// // export default HomeEdit;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useAuth } from "../../AuthContext";
// import iziToast from "izitoast";
// import SideBar from "./SideBar";
// import Navbar from "./Navbar";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// const HomeEdit = () => {
//   const { apipath, filepath } = useAuth();
//   const [aboutImageUrl, setAboutImageUrl] = useState(null);
//   const [whyUsImageUrl, setWhyUsImageUrl] = useState(null);
//   const [activeTab, setActiveTab] = useState("banner");
//   const [aboutContent, setAboutContent] = useState('');
//   const [whyUsContent, setWhyUsContent] = useState('');

//   const openTab = (tabName) => {
//     setActiveTab(tabName);
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     const name = event.target.name;
    
//     if (file) {
//       setHomePageDetails((prevDetails) => ({
//         ...prevDetails,
//         [name]: file,
//       }));

//       const reader = new FileReader();
//       reader.onload = (e) => {
//         if (name === "aboutImage") setAboutImageUrl(e.target.result);
//         if (name === "whyUsImage") setWhyUsImageUrl(e.target.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const [homePageDetails, setHomePageDetails] = useState({
//     courseHeading: "",
//     courseSubHeading: "",
//     courseText: "",
//     aboutHeading: "",
//     aboutText1: "",
//     whyUsText:"",
//     whyUsImage:null,
//     aboutImage:null
//   });

//   useEffect(() => {
//     fetchHomePageDetails();
//   }, []);

//   const fetchHomePageDetails = async () => {
//     try {
//       const response = await axios.get(
//         `${apipath}/homePage/details/66d6e7098036cd592b09b027`
//       );

//       const { aboutContent, whyUsContent } = response.data.homePage;
//       setHomePageDetails(response.data.homePage);
//       setAboutContent(aboutContent);
//       setWhyUsContent(whyUsContent);
//       setAboutImageUrl(filepath + response.data.homePage.aboutImage);
//       setWhyUsImageUrl(filepath + response.data.homePage.whyUsImage);
//     } catch (error) {
//       console.error("Error fetching home page details:", error);
//     }
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.put(
//         `${apipath}/homePage/details/66d6e7098036cd592b09b027`,
//         homePageDetails,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       if (response.status === 200) {
//         iziToast.success({
//           message: "Home page details updated successfully!",
//           position: "topCenter",
//         });
//       } else {
//         iziToast.error({
//           message: "Home page details update failed",
//           position: "topCenter",
//         });
//       }
//     } catch (error) {
//       console.error("Error updating home page details:", error);
//       iziToast.error({
//         message: "An error occurred",
//         position: "topCenter",
//       });
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setHomePageDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleEditorChange = (event, editor) => {
//     const data = editor.getData();
//     if (activeTab === "about") {
//       setAboutContent(data);
//       setHomePageDetails(prevDetails => ({
//         ...prevDetails,
//         aboutText1: data,
//       }));
//     } else {
//       setWhyUsContent(data);
//       setHomePageDetails(prevDetails => ({
//         ...prevDetails,
//         whyUsText: data,
//       }));
//     }
//   };

//   return (
//     <>
//      <SideBar />
//      <Navbar />

//       <section className="home">
//         <div className="toggle-sidebar" style={{ display: "none" }}>
//           <i className="bi bi-menu"></i>
//         </div>

//         <div className="container pt-3">
//           <div className="row home-toggletab">
//             <h4>Edit Homepage Information</h4>
//             <div className="col-lg-11 hometab-column">
              
//               <div className="tab">
//                 <button
//                   className={`tablinks  ${activeTab === "banner" && "active"}`}
//                   onClick={() => openTab("banner")}
//                   id="banner-button"
//                 >
//                   Main Banner
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "about" && "active"}`}
//                   onClick={() => openTab("about")}
//                   id="about-button"
//                 >
//                   About Instructor
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "training" && "active"}`}
//                   onClick={() => openTab("training")}
//                   id="training-button"
//                 >
//                   Why Us
//                 </button>
//               </div>

//               <div
//                 id="banner"
//                 className={`tabcontent ${
//                   activeTab === "banner" ? "active" : ""
//                 }`}
//               >
//                 <div className="season_content">
//                   <form>
//                     <div className="mb-4">
//                       <h5>Title</h5>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="courseHeading"
//                         value={homePageDetails.courseHeading}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="mb-4">
//                       <h5>Subtitle</h5>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="courseSubHeading"
//                         value={homePageDetails.courseSubHeading}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="mb-4">
//                       <h5>Content</h5>
//                       <textarea
//                         type="text"
//                         className="form-control"
//                         name="courseText"
//                         value={homePageDetails.courseText}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </form>
//                   <button
//                     type="button"
//                     className="btn btn-success"
//                     onClick={handleUpdate}
//                   >
//                     Update
//                   </button>
//                 </div>
//               </div>

//               <div
//                 id="about"
//                 className={`tabcontent ${
//                   activeTab === "about" ? "active" : ""
//                 }`}
//               >
//                 <div className="season_content">
//                   <form>
//                     <div className="mb-4">
//                       <h5>Title</h5>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="title"
//                         aria-describedby="title"
//                         placeholder="About Meiyappan Muthu"
//                         name="aboutHeading"
//                         value={homePageDetails.aboutHeading}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="mb-4">
//                       <h5>Content</h5>
//                       <CKEditor
//                         editor={ClassicEditor}
//                         data={aboutContent}
//                         onChange={ handleEditorChange }
//                         name="aboutText1"
//                       />
//                     </div>

//                     <div className="row g-3">
//                       <div className="col-md-6 mb-4">
//                         <h5>Image</h5>
//                         <div className="imgbox">
//                           <img
//                             src={aboutImageUrl}
//                             id="imgPreview"
//                             alt="About"
//                             width={250} height={200}
//                                                 style={{ borderRadius: '35px' }}
//                                                 className="mt-3"
//                           />
//                         </div>
//                         <input
//                           className="form-control mt-4"
//                           type="file"
//                           id="formFile"
//                           name="aboutImage"
//                           onChange={handleFileChange}
//                         />
//                       </div>
//                     </div>
//                   </form>
//                   <button
//                     type="button"
//                     className="btn btn-success"
//                     onClick={handleUpdate}
//                   >
//                     Update
//                   </button>
//                 </div>
//               </div>

//               <div
//                 id="training"
//                 className={`tabcontent ${
//                   activeTab === "training" ? "active" : ""
//                 }`}
//               >
//                 <div className="season_content">
//                   <form>
//                     <div className="mb-4">
//                       <h5>Title</h5>
//                       <input
//                         type="text"
//                         className="form-control"
//                         name="whyUsText"
//                         value={homePageDetails.whyUsText}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="mb-4">
//                       <h5>Content</h5>
//                       <CKEditor
//                         editor={ClassicEditor}
//                         data={whyUsContent}
//                         onChange={handleEditorChange }
//                          name="whyUsText"
//                       />
//                     </div>
//                     <div className="row g-3">
//                       <div className="col-md-6 mb-4">
//                         <h5>Image</h5>
//                         <div className="imgbox">
//                           <img
//                             src={whyUsImageUrl}
//                             id="imgPreview"
//                             alt="Why Us"
//                             width={250} height={200}
//                                                 style={{ borderRadius: '35px' }}
//                                                 className="mt-3"
//                           />
//                         </div>
//                         <input
//                           className="form-control mt-4"
//                           type="file"
//                           id="formFile"
//                           name="whyUsImage"
//                           onChange={handleFileChange}
//                         />
//                       </div>
//                     </div>
//                   </form>
//                   <button
//                     type="button"
//                     className="btn btn-success"
//                     onClick={handleUpdate}
//                   >
//                     Update
//                   </button>
//                 </div>
//               </div>
              
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default HomeEdit;




import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import iziToast from "izitoast";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const HomeEdit = () => {
  const { apipath, filepath } = useAuth();
  const [aboutImageUrl, setAboutImageUrl] = useState(null);
  const [whyUsImageUrl, setWhyUsImageUrl] = useState(null);
  const [activeTab, setActiveTab] = useState("banner");
  const [aboutContent, setAboutContent] = useState('');
  const [whyUsContent, setWhyUsContent] = useState('');

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;
    
    if (file) {
      setHomePageDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file,
      }));

      const reader = new FileReader();
      reader.onload = (e) => {
        if (name === "aboutImage") setAboutImageUrl(e.target.result);
        if (name === "whyUsImage") setWhyUsImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [homePageDetails, setHomePageDetails] = useState({
    courseHeading: "",
    courseSubHeading: "",
    courseText: "",
    aboutHeading: "",
    aboutText1: "",
    whyUsText: "",
    whyUsImage: null,
    aboutImage: null,
  });

  useEffect(() => {
    fetchHomePageDetails();
  }, []);

  const fetchHomePageDetails = async () => {
    try {
      const response = await axios.get(
        `${apipath}/homePage/details/66a3c8240788bc05987fc657`
      );

      if (response.data && response.data.homePage) {
        const { aboutText1, whyUsText, aboutImage, whyUsImage } = response.data.homePage;
        setHomePageDetails(response.data.homePage);
        setAboutContent(aboutText1 || '');
        setWhyUsContent(whyUsText || '');
        setAboutImageUrl(aboutImage ? filepath + aboutImage : null);
        setWhyUsImageUrl(whyUsImage ? filepath + whyUsImage : null);
      } else {
        console.error("Home page details are missing in the API response");
      }
    } catch (error) {
      console.error("Error fetching home page details:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in homePageDetails) {
        formData.append(key, homePageDetails[key]);
      }

      const response = await axios.put(
        `${apipath}/homePage/details/66a3c8240788bc05987fc657`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        iziToast.success({
          message: "Home page details updated successfully!",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Home page details update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error updating home page details:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHomePageDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    if (activeTab === "about") {
      setAboutContent(data);
      setHomePageDetails(prevDetails => ({
        ...prevDetails,
        aboutText1: data,
      }));
    } else {
      setWhyUsContent(data);
      setHomePageDetails(prevDetails => ({
        ...prevDetails,
        whyUsText: data,
      }));
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row home-toggletab">
            <h4>Edit Homepage Information</h4>
            <div className="col-lg-11 hometab-column">

              <div className="tab">
                <button
                  className={`tablinks  ${activeTab === "banner" && "active"}`}
                  onClick={() => openTab("banner")}
                  id="banner-button"
                >
                  Main Banner
                </button>
                <button
                  className={`tablinks ${activeTab === "about" && "active"}`}
                  onClick={() => openTab("about")}
                  id="about-button"
                >
                  About Instructor
                </button>
                <button
                  className={`tablinks ${activeTab === "training" && "active"}`}
                  onClick={() => openTab("training")}
                  id="training-button"
                >
                  Why Us
                </button>
              </div>

              <div
                id="banner"
                className={`tabcontent ${
                  activeTab === "banner" ? "active" : ""
                }`}
              >
                <div className="season_content">
                  <form>
                    <div className="mb-4">
                      <h5>Title</h5>
                      <input
                        type="text"
                        className="form-control"
                        name="courseHeading"
                        value={homePageDetails.courseHeading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Subtitle</h5>
                      <input
                        type="text"
                        className="form-control"
                        name="courseSubHeading"
                        value={homePageDetails.courseSubHeading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Content</h5>
                      <textarea
                        type="text"
                        className="form-control"
                        name="courseText"
                        value={homePageDetails.courseText}
                        onChange={handleChange}
                      />
                    </div>
                  </form>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>

              <div
                id="about"
                className={`tabcontent ${
                  activeTab === "about" ? "active" : ""
                }`}
              >
                <div className="season_content">
                  <form>
                    <div className="mb-4">
                      <h5>Title</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        aria-describedby="title"
                        placeholder="About Meiyappan Muthu"
                        name="aboutHeading"
                        value={homePageDetails.aboutHeading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Content</h5>
                      <CKEditor
                        editor={ClassicEditor}
                        data={aboutContent}
                        onChange={handleEditorChange}
                        name="aboutText1"
                      />
                    </div>

                    <div className="row g-3">
                      <div className="col-md-6 mb-4">
                        <h5>Image</h5>
                        <div className="imgbox">
                          {aboutImageUrl && (
                            <img
                              src={aboutImageUrl}
                              id="imgPreview"
                              alt="About"
                              width={250} height={200}
                              style={{ borderRadius: '35px' }}
                              className="mt-3"
                            />
                          )}
                        </div>
                        <input
                          className="form-control mt-4"
                          type="file"
                          id="aboutImage"
                          name="aboutImage"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </form>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>

              <div
                id="training"
                className={`tabcontent ${
                  activeTab === "training" ? "active" : ""
                }`}
              >
                <div className="season_content">
                  <form>
                    <div className="mb-4">
                      <h5>Content</h5>
                      <CKEditor
                        editor={ClassicEditor}
                        data={whyUsContent}
                        onChange={handleEditorChange}
                        name="whyUsText"
                      />
                    </div>

                    <div className="row g-3">
                      <div className="col-md-6 mb-4">
                        <h5>Image</h5>
                        <div className="imgbox">
                          {whyUsImageUrl && (
                            <img
                              src={whyUsImageUrl}
                              id="imgPreview"
                              alt="Why Us"
                              width={250} height={200}
                              style={{ borderRadius: '35px' }}
                              className="mt-3"
                            />
                          )}
                        </div>
                        <input
                          className="form-control mt-4"
                          type="file"
                          id="whyUsImage"
                          name="whyUsImage"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </form>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeEdit;

import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axios from "axios";
import { useAuth } from '../../AuthContext';
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

const NavbarEdit = () => {
  const { apipath } = useAuth();
  const [navbarDetails, setNavbarDetails] = useState({
    navbarHome: "",
    navbarCourses: "",
    navbarBlogs: "",
    navbarContactUs: "",
  });

  useEffect(() => {
    fetchNavbarDetails();
  }, []);

  const fetchNavbarDetails = async () => {
    try {
      const response = await axios.get(
        `${apipath}/homePage/details/66a3c8240788bc05987fc657`
      );
      setNavbarDetails(response.data.homePage);
    } catch (error) {
      console.error("Error fetching Navbar Details:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${apipath}/homePage/details/66a3c8240788bc05987fc657`,
        navbarDetails
      );
      if (response.status === 200) {
        iziToast.success({
          message: "Navbar details updated successfully!",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Navbar details update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error updating Navbar details:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNavbarDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Navbar Information</h5>
            <div className="row nav-column">
              <div>
                <form className="row" onSubmit={handleUpdate}>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-item1" className="form-label">
                      Home
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      placeholder="Home"
                      name="navbarHome"
                      value={navbarDetails.navbarHome}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-item2" className="form-label">
                      Nav Item
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item2"
                      placeholder="Courses"
                      name="navbarCourses"
                      value={navbarDetails.navbarCourses}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-item3" className="form-label">
                      Nav Item
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item3"
                      placeholder="Blogs"
                      name="navbarBlogs"
                      value={navbarDetails.navbarBlogs}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-item4" className="form-label">
                      Nav Item
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item4"
                      placeholder="Contact Us"
                      name="navbarContactUs"
                      value={navbarDetails.navbarContactUs}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <button type="submit" className="btn btn-success">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NavbarEdit;

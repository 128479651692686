import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { useAuth } from '../AuthContext';
import iziToast from 'izitoast';

const Forgot = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been sent
  const { apipath } = useAuth();
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleResendOtp = (event) => {
    event.preventDefault();
    // Send request to resend OTP
    fetch(apipath + '/mail/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Incorrect email');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: 'OTP sent successfully',
        });
        setOtpSent(true); // Set OTP sent state to true
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to resend OTP, Please check your Email id',
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(apipath + '/mail/verify-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, otp }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Incorrect otp. Please enter valid Otp');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: data.message || 'OTP verified successfully',
        });
        navigate("/reset", { state: { email } }); // Pass email to the reset page
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to verify OTP',
        });
      });
  };


  return (
    <>
    <Navbar />
      <div className="container-fluid login-main">
        <div className="col-lg-6 login-c">
          <div className="col-lg-8 mx-auto login">
            <h1>Forgot Password ?</h1>
            <h3>Don't worry we can help</h3>
            <form onSubmit={handleSubmit}>
            <div className="login-form">
              <label htmlFor="email">Email Address</label>
              <input type="email"  placeholder="Enter Your email"
                  value={email}
                  onChange={handleEmailChange}
                  readOnly={otpSent}
                  required />
            </div>

            {otpSent && (
                <div className="login-form">
                  <label htmlFor="name">Enter OTP </label>
                  <input type="text"
                     id="otp"
                      placeholder="Enter OTP sent on your email ID provided"
                      value={otp}
                      onChange={handleOtpChange}
                    required
                     />
                </div>
              )}
              {!otpSent && (
                <div className="login-button col-lg-8 mx-auto">
                  <button onClick={handleResendOtp}>
                    Resend OTP <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              )}
              {otpSent && (
                <div className="login-button col-lg-8 mx-auto">
                  <button>Continue</button>
                </div>

              )}
            {/* <div className="login-button">
              <Link to="/reset"><button>Continue</button></Link>
            </div> */}
            <div className="acc">
              <p>
                Remembered your password ?{" "}
                <span>
                  <Link to="/signin">Sign in</Link>
                </span>
              </p>
            </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 login-image">
          <img src="imgs/girl.png" alt="" />
        </div>
      </div>
      <Footer classes={true} />
    </>
  )
}

export default Forgot;
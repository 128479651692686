
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import iziToast from "izitoast";

const Congrats = () => {
  const { bookingId } = useParams();
  const { apipath } = useAuth();
  const userEmail = localStorage.getItem('userEmail');
  const clientEmail = 'info@scrumglobal.org'
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('userName') || 'Customer';
  
  const [loading, setLoading] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [error, setError] = useState(null);

  console.log("bookingId : ",bookingId);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });

    // Fetch order details and update the booking
    const fetchAndUpdateBookingDetails = async () => {
      setLoading(true);
      try {    

        const response = await axios.get(`${apipath}/booking/details/${bookingId}`);
        const booking = response.data.booking;

        const updatedBookingResponse = await axios.put(`${apipath}/booking/details/${bookingId}`, {
          ...bookingDetails,
          bookingStatus: true,
          paymentStatus: true,
        });
    
        iziToast.success({
          title: "Success",
          message: "Booking course successfully.",
        });
    
        const updatedBooking = updatedBookingResponse.data.booking;
        setBookingDetails(updatedBooking);
    
        if (clientEmail) {
          const payloads = {
            userEmail: clientEmail,
            name: localStorage.getItem('name') || 'Customer',
            courseName: booking.bookingCourse,
            selectedTimeSlot: booking.slotDate,
            instructor: booking.bookingInstructor,
            price: booking.totalPrice,
          };

          const clientEmailResponse = await fetch(`${apipath}/booking/confirm/send-client`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payloads),
          });
        }

        if (userEmail) {
          const payload = {
            userEmail: userEmail,
            name: localStorage.getItem('name') || 'Customer',
            courseName: booking.bookingCourse,
            selectedTimeSlot: booking.slotDate,
            instructor: booking.bookingInstructor,
            price: booking.totalPrice,
          };

          const emailResponse = await fetch(`${apipath}/booking/confirm`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
    
          if (!emailResponse.ok) {
            const errorText = await emailResponse.text();
            console.error("Response Text:", errorText);
            throw new Error('Failed to send confirmation email.');
          }
    
          const emailData = await emailResponse.json();
          iziToast.success({
            title: "Success",
            message: emailData.message || "Payment successful. Confirmation email sent.",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        iziToast.error({
          title: "Error",
          message: error.message || "Failed to update booking details.",
        });
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    

    fetchAndUpdateBookingDetails();
  }, [apipath, userEmail]);

  return (
    <>
      <Navbar />

      <div className="container">
        <div className="col-lg-10 mx-auto congrats">
          <div className="congrats-img d-flex justify-content-center">
            <img src={`${process.env.PUBLIC_URL}/imgs/congrats.png`} width="150" alt="" />
          </div>
          <h1>Congratulations !!</h1>
          <h6>Your payment was successful</h6>         
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Congrats;

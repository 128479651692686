// import React, { useState, useEffect } from "react";
// import { useParams,useNavigate } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import axios from 'axios';
// import { useAuth } from "../AuthContext";

// const Blogdetails = () => {
//   const { apipath } = useAuth();
//   const { blogId } = useParams();
//   const [blog, setBlog] = useState(null);

//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     website: '',
//     comment: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(apipath + '/blogComment/add', formData);
//       console.log('Response from API:', response.data);
//       // Optionally, you can handle success or show a success message here
//       navigate('/contactSubmit');
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       // Optionally, you can handle errors or show an error message here
//     }
//   };

//   useEffect(() => {
//     const fetchBlogDetails = async () => {
//       try {
//         const response = await fetch(apipath + `/blog/details/${blogId}`);
//         if (response.ok) {
//           const data = await response.json();
//           setBlog(data.blog);
//         } else {
//           throw new Error("Failed to fetch blog details");
//         }
//       } catch (error) {
//         console.error("Error fetching blog details:", error);
//       }
//     };

//     fetchBlogDetails();
//   }, [blogId]);

//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid bd-banner">
//         <div className="bd-box">
//           <h1>Blog</h1>
//           <p>The proper uѕе оf ѕаfеtу ѕіgnѕ іѕ a соmрulѕоrу requirement.</p>
//           <div class="input-group">
//             <input
//               type="text"
//               class="form-control"
//               placeholder="Blog Search"
//               aria-label="blog search"
//               aria-describedby="basic-addon2"
//             />
//             <div class="input-group-append">
//               <span class="input-group-text" id="basic-addon2">
//                 Search Now
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container">
//         <div className="col-lg-10 mx-auto bd-container">
//         {blog && (
//           <>
//           <h1 className="col-lg-10 my-4">
//             {/* When the musics over turn off the light says Jim Morrison */}
//             {blog.blogHeading}
//           </h1>
//           <div className="profile">
//             <img src={`${process.env.PUBLIC_URL}/imgs/profile.png`} alt="" />
//             {/* <span>Shuvo Roy | July 4, 2024 | 3 min read</span> */}

//             <span> {blog.blogTeacherName} | {blog.blogScheduleMonth} | 3 min read</span>
//           </div>
//           <div className="bd-img">
//             <img src={`${process.env.PUBLIC_URL}/imgs/Agp-Blog-Jan-3 1.png`} alt="" />
//           </div>
//           <p>
//             {/* Lemagni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//             porro quisquam est, qui dolorem ipsum quia dolor sit ametempor
//             incididunt ut labore et dolore magna aliqua. Ut enim ad minim
//             veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
//             ea commodo */}
//             {blog.blogText}
//           </p>
//           <p>
//             {/* consequat. Duis aute irure dolor in reprehenderit in voluptate velit
//             esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//             cupidatat non proident, sunt in culpa qui officia deserunt mollit
//             anim id est laborum. Sed ut perspiciatis unde omnis iste natus error
//             sit volup */}
//           </p>

//           {/* <h3>Mauris et neque hendrerit bortis turpis giat nisl</h3> */}
//           <h3>{blog.blogSubHeading}</h3>
//           <p>
//             {/* Lemagni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//             porro quisquam est, qui dolorem ipsum quia dolor sit ametempor
//             incididunt ut labore et dolore magna aliqua. Ut enim ad minim
//             veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
//             ea commodo */}
//             {blog.blogSubText}
//           </p>

//           {/* <h3>Mauris et neque hendrerit bortis turpis giat nisl</h3>
//           <p>
//             Lemagni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//             porro quisquam est, qui dolorem ipsum quia dolor sit ametempor
//             incididunt ut labore et dolore magna aliqua. Ut enim ad minim
//             veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
//             ea commodo
//           </p> */}
//           </>
//           )}
//         </div>

//         <div className="col-lg-8 mx-auto bd-form">
//           <h1>Comments</h1>
//           <form onSubmit={handleSubmit}>
//             <div className="row my-3">
//             <div className="col-lg-6">
//               <div>
//                 <label htmlFor="name">Name <span>*</span></label>
//                 <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" required />
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div>
//                 <label htmlFor="name">Email <span>*</span></label>
//                 <input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" required />
//               </div>
//             </div>
//             </div>
//             <div className="col-lg-12 my-3">
//               <label htmlFor="website">
//               Website <span>*</span>
//               </label>
//               <input type="text" name="website" value={formData.website} onChange={handleChange} placeholder="Enter your website" required />
//             </div>
//             <div className="col-lg-12 my-3">
//               <label htmlFor="comment">
//               Post a comment <span>*</span>
//               </label>
//               <textarea type="text" name="comment" value={formData.comment} onChange={handleChange} placeholder="Write comment" required></textarea>
//             </div>
//             <div className="bd-button">
//               <button>Submit Now</button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Blogdetails;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Blogdetails = () => {
  const { apipath, filepath } = useAuth();
  const [blogBannerImage, setBlogBannerImage] = useState([]);
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    blogId: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.website.trim()) {
      newErrors.website = "Website is required";
    } else if (
      !/^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/.test(formData.website)
    ) {
      newErrors.website = "Website is invalid";
    }

    if (!formData.comment.trim()) {
      newErrors.comment = "Comment is required";
    } else if (formData.comment.length < 10) {
      newErrors.comment = "Comment must be at least 10 characters";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(
          apipath + "/blogComment/add",
          formData
        );
        console.log("Response from API:", response.data);
        // Optionally, you can handle success or show a success message here
        navigate("/contactSubmit");
      } catch (error) {
        console.error("Error submitting form:", error);
        // Optionally, you can handle errors or show an error message here
      }
    }
  };

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(apipath + `/blog/details/${blogId}`);
        if (response.ok) {
          const data = await response.json();
          setBlog(data.blog);
        } else {
          throw new Error("Failed to fetch blog details");
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogDetails();
  }, [blogId, apipath]);

  useEffect(() => {
    async function fetchBlogBannerImage() {
      try {
        const response = await axios.get(apipath + "/homePage/details");
        setBlogBannerImage(response.data.homePage[0]);
      } catch (error) {
        console.error("Error fetching home page data:", error);
      }
    }
    fetchBlogBannerImage();
  }, []);

  return (
    <>
      <Navbar />

      {/* <div
        className="container-fluid bd-banner"
        style={{
          backgroundImage: blogBannerImage ? `url(${filepath + blogBannerImage.blogBannerImage})` : 'none',
          backgroundSize: 'contain',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8%',
        }}
      >
      </div> */}

      <div className="container">
        <div className="col-lg-10 mx-auto bd-container">
          {blog && (
            <>
              <h1 className="col-lg-10 my-4">{blog.blogHeading}</h1>
              <div className="profile">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/imgs/profile.png`}
                  alt=""
                /> */}
                <img
                    src={filepath + blog.teacherImage}
                    width={65} height={65}
                    style={{ borderRadius: '40px' }}
                    alt={"image not found"}
                  />
                <span>
                  {blog.blogTeacherName} | {blog.blogScheduleMonth} | 3 min read
                </span>
              </div>
              <div className="bd-img">
                <img
                  src={filepath + blog.blogImage}
                  alt={"image not found"}
                  width={320}
                  height={520}
                  style={{ borderRadius: "35px" }}
                />
              </div>
              <p>{blog.blogText}</p>
              <div dangerouslySetInnerHTML={{ __html: blog.blogContent }} />
            </>
          )}
        </div>

        <div className="col-lg-8 mx-auto bd-form">
          <h1>Comments</h1>
          <form onSubmit={handleSubmit}>
            <div className="row my-3">
              <div className="col-lg-6">
                <input
                  type="text"
                  name="blogId"
                  value={blogId}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                  hidden
                />
                <div>
                  <label htmlFor="name">
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                    required
                  />
                  {errors.name && <div className="error">{errors.name}</div>}
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label htmlFor="email">
                    Email <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>
              </div>
            </div>
            <div className="col-lg-12 my-3">
              <label htmlFor="website">
                Website <span>*</span>
              </label>
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
                placeholder="Enter your website"
                required
              />
              {errors.website && <div className="error">{errors.website}</div>}
            </div>
            <div className="col-lg-12 my-3">
              <label htmlFor="comment">
                Post a comment <span>*</span>
              </label>
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                placeholder="Write comment"
                required
                minLength="10"
              ></textarea>
              {errors.comment && <div className="error">{errors.comment}</div>}
            </div>
            <div className="bd-button">
              <button>Submit Now</button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blogdetails;

import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import Navbar from "./Navbar";

const AdminTable = () => {
  const dataTableRef = useRef();
  const dataTableRef1 = useRef();
  const dataTableRef2 = useRef();
  const dataTableRef3 = useRef();
  const [stockTab, setStockTab] = useState("stock");

  useEffect(() => {
    $(dataTableRef.current).DataTable();
    $(dataTableRef1.current).DataTable();
    $(dataTableRef2.current).DataTable();
    $(dataTableRef3.current).DataTable();
  }, [stockTab]);

  const handleStockTab = (tab) => {
    setStockTab(tab);
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container">
          <div className="foot-tablerow">
            <h5>Stock Market</h5>
            <div className="stock-tabs">
              <button
                onClick={() => handleStockTab("stock")}
                className={`stock-btn ${
                  stockTab === "stock" && "stock-btn-active"
                }`}
              >
                Stock
              </button>
              <button
                onClick={() => handleStockTab("mf")}
                className={`stock-btn ${
                  stockTab === "mf" && "stock-btn-active"
                }`}
              >
                MF
              </button>
              <button
                onClick={() => handleStockTab("etf")}
                className={`stock-btn ${
                  stockTab === "etf" && "stock-btn-active"
                }`}
              >
                ETF
              </button>
              <button
                onClick={() => handleStockTab("index")}
                className={`stock-btn ${
                  stockTab === "index" && "stock-btn-active"
                }`}
              >
                Index
              </button>
            </div>
            {stockTab === "stock" && <div className="row">
            <div className="col-lg-8 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable-2" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Stock ID</th>
                      <th>Stock Symbol</th>
                      <th>Stock Name</th>
                      
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
                <div className="stock-select">
                    <p>Select stock end point whose parameters you want to see </p>
                    <select name="" id="">
                        <option value="">Profile</option>
                        <option value="">Dividends</option>
                        <option value="">Profile</option>
                        <option value="">Profile</option>
                    </select>
                </div>
            </div>
            </div>}
            {stockTab === "mf" && <div className="row">
            <div className="col-lg-8 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable-2" className="table" ref={dataTableRef1}>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>MF Stock ID</th>
                      <th>MF Stock Symbol</th>
                      <th>MF Stock Name</th>
                      
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
                <div className="stock-select">
                    <p>Select stock end point whose parameters you want to see </p>
                    <select name="" id="">
                        <option value="">Profile</option>
                        <option value="">Dividends</option>
                        <option value="">Profile</option>
                        <option value="">Profile</option>
                    </select>
                </div>
            </div>
            </div>}
            {stockTab === "etf" && <div className="row">
            <div className="col-lg-8 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable-2" className="table" ref={dataTableRef2}>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>ETF Stock ID</th>
                      <th>ETF Stock Symbol</th>
                      <th>ETF Stock Name</th>
                      
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
                <div className="stock-select">
                    <p>Select stock end point whose parameters you want to see </p>
                    <select name="" id="">
                        <option value="">Profile</option>
                        <option value="">Dividends</option>
                        <option value="">Profile</option>
                        <option value="">Profile</option>
                    </select>
                </div>
            </div>
            </div>}
            {stockTab === "index" && <div className="row">
            <div className="col-lg-8 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable-2" className="table" ref={dataTableRef3}>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Id Stock ID</th>
                      <th>Id Stock Symbol</th>
                      <th>Id Stock Name</th>
                      
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>NSESStock</td>
                      <td>INFY</td>
                      <td>Infosys Ltd</td>
                      <td>
                        <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
                <div className="stock-select">
                    <p>Select stock end point whose parameters you want to see </p>
                    <select name="" id="">
                        <option value="">Profile</option>
                        <option value="">Dividends</option>
                        <option value="">Profile</option>
                        <option value="">Profile</option>
                    </select>
                </div>
            </div>
            </div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminTable;

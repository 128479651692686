import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { useAuth } from '../AuthContext';
import iziToast from 'izitoast';
import OtpTimer from "otp-timer";

const RegOtpVerify = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const { apipath } = useAuth();
    const { emailId } = useParams();
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }, []);
  
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const emailFromParams = urlParams.get("email");
      if (emailFromParams) {
        setEmail(emailFromParams);
      }
    }, []);
  
    const handleOtpChange = (event) => {
      setOtp(event.target.value);
    };
  
    const handleResendOtp = () => {
      console.log("resend otp");
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
  
      fetch(apipath + "/mail/reg-verify-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then(err => {
              throw new Error(err.message || 'Incorrect otp. Please enter valid Otp');
            });
          }
          return response.json();
        })
        .then((data) => {
          iziToast.success({
            title: "Success",
            message: data.message || "OTP verified successfully",
          });
          navigate("/signin");
        })
        .catch((error) => {
          console.error("Error:", error);
          iziToast.error({
            title: "Error",
            message: error.message || "Failed to verify OTP",
          });
        });
    };
  
  return (
    <>
    <Navbar />
      <div className="container-fluid login-main">
        <div className="col-lg-6 login-c">
          <div className="col-lg-8 mx-auto login">
          <h1>Verify OTP</h1>
          <p>Please enter OTP</p>
            <form onSubmit={handleSubmit}>
            <div className="login-form">
              <label htmlFor="email">Email </label>
              <input type="text"
                id="email"
                placeholder="Enter Your email"
                value={email}
                readOnly
                required />
            </div>

            <div className="login-form">
              <label htmlFor="email">Enter OTP </label>
              <input type="text"
                id="otp"
                placeholder="Enter OTP sent on your email ID provided"
                value={otp}
                onChange={handleOtpChange}
                required
                />
            </div>

            <div className="forgot d-flex justify-content-end mt-1">
              
                  
              <OtpTimer
                minutes={0}
                seconds={60}
                text={<span className="otp-timer-text">Resend OTP In : </span>}
                ButtonText={<span className="otp-timer-button">Resend OTP</span>}
                textColor={"#38a3a5"}
                buttonColor={"#38a3a5"}
                background={"#fff"}
                resend={handleResendOtp}
              />
            
          </div>
            
            <div className="login-button">
            <button>OTP Verify</button>
            </div>
            <div className="acc">
              <p>
                Remembered your password ?{" "}
                <span>
                  <Link to="/signin">Sign in</Link>
                </span>
              </p>
            </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 login-image">
          <img src={`${process.env.PUBLIC_URL}/imgs/loginImage.jpeg`}/>
        </div>
        {/* </div> */}
      </div>


      <Footer classes={true} />
    
    </>
  )
}

export default RegOtpVerify;
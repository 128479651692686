import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';

const Test = () => {
//   const { apipath } = useAuth();

//   // This function initiates the payment process
//   const buyFunction = async () => {
//     try {
//       const response = await axios.post(apipath + '/payment/booking');
//       if (response.status === 200) {
//         // Redirect to the payment URL if the request is successful
//         window.location.href = response.data.url;
//       }
//     } catch (error) {
//       console.error('Error processing payment:', error);
//     }
//   };

//   return (
//     <div>
//       <h1>Buy a T-Shirt</h1>
//       <button onClick={buyFunction}>Buy Now</button>
//     </div>
//   );
// };

useEffect(() => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "auto",
  });
}, []); 

const { apipath } = useAuth();
  const [courseDetails, setCourseDetails] = useState(null);

  const fetchCourseDetails = async (courseId) => {
    try {
      const response = await axios.get(`${apipath}/course/details/66dac70ad83f327a1fca415d`);
      if (response.status === 200) {
        setCourseDetails(response.data.course);
        console.log();
        
      } else {
        console.error('Failed to fetch course details');
      }
    } catch (error) {
      console.error('Error fetching course details:', error);
    }
  };

  const buyFunction = async () => {
    if (!courseDetails) {
      console.error('Course details not loaded yet.');
      return;
    }

    try {
      const response = await axios.post(`${apipath}/payment/booking`, {
        course_id: courseDetails._id,
        totalPrice: courseDetails.fee + courseDetails.gst, // Assuming fee + gst is the total price
        userEmail: 'user@example.com', // Replace with dynamic user email
        bookingTimeSlot: '10 AM - 12 PM', // Example time slot, update as needed
        courseName: courseDetails.courseName,
        instructor: 'Instructor Name', // Replace with actual instructor name
        bookingCourse: 'Course Booking', // Example booking info
        bookingInstructor: 'Instructor Info', // Example booking instructor
        bookingPrice: courseDetails.fee,
        bookingGST: courseDetails.gst,
        bookingStatus: 'Pending',
        paymentStatus: 'Unpaid',
        bookingId: 'ABC123', // Example booking ID, replace dynamically
      });

      if (response.status === 200) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  useEffect(() => {
    // Fetch course details for a specific courseId (replace with actual courseId)
    fetchCourseDetails('66dac70ad83f327a1fca415d');
  }, []);

  return (
    <div>
      <h1>Buy a T-Shirt</h1>
      {courseDetails ? (
        <div>
          <h2>{courseDetails.courseName}</h2>
          <p>Price: {courseDetails.fee}</p>
          <p>GST: {courseDetails.gst}</p>
          <button onClick={buyFunction}>Buy Now</button>
        </div>
      ) : (
        <p>Loading course details...</p>
      )}
    </div>
  );
};

export default Test;

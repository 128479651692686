// // import React, { useEffect, useState } from 'react';
// // import { useNavigate } from "react-router-dom";
// // import Navbar from './Navbar';
// // import Footer from './Footer';
// // import axios from 'axios';
// // import { useAuth } from '../AuthContext';

// // const Contact = () => {
// //   const { apipath } = useAuth();
// //   const navigate = useNavigate();
// //   const [formData, setFormData] = useState({
// //     name: '',
// //     email: '',
// //     phone: '',
// //     city: '',
// //     description: ''
// //   });

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value
// //     });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const response = await axios.post(apipath + '/contactUs/add', formData);
// //       console.log('Response from API:', response.data);
// //       navigate('/contactSubmit');
// //     } catch (error) {
// //       console.error('Error submitting form:', error);
// //     }
// //   };

// //   const [contactData, setContactData] = useState(null);

// //     useEffect(() => {
// //         async function fetchContactData() {
// //             try {
// //                 const response = await axios.get(apipath + '/homePage/details');
// //                 setContactData(response.data.homePage[0]);
// //             } catch (error) {
// //                 console.error('Error fetching contact data:', error);
// //             }
// //         }
// //         fetchContactData();
// //     }, []);

// //   return (
// //     <>
// //       <Navbar />

// //       <div className="container-fluid contact">
// //       {contactData && (
// //         <div className="contact-banner">
// //           <h1>{contactData.contentHeading}</h1>
// //           <p>{contactData.contentText}</p>
// //         </div>
// //       )}
// //       </div>

// //       <div className="container">
// //         <div className="col-lg-10 mx-auto c-form">
// //           <h1>Send us a message</h1>
// //           <form onSubmit={handleSubmit}>
// //             <div className="row">
// //               <div className="col-lg-6">
// //                 <div className="c-field">
// //                   <label htmlFor="name">Name <span>*</span></label>
// //                   <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your name" required />
// //                 </div>
// //               </div>
// //               <div className="col-lg-6">
// //                 <div className="c-field">
// //                   <label htmlFor="email">Email <span>*</span></label>
// //                   <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email Address" required />
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="row">
// //               <div className="col-lg-6">
// //                 <div className="c-field">
// //                   <label htmlFor="phone">Phone Number</label>
// //                   <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="+91" required/>
// //                 </div>
// //               </div>
// //               <div className="col-lg-6">
// //                 <div className="c-field">
// //                   <label htmlFor="city">City </label>
// //                   <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="City" required/>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="row">
// //               <div className="col-lg-12">
// //                 <div className="c-field">
// //                   <label htmlFor="description">Description</label>
// //                   <textarea type="text" name="description" value={formData.description} onChange={handleChange} placeholder="Description..." maxLength="50" required/>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="contact-button">
// //               <button type="submit">Send Message</button>
// //             </div>
// //           </form>
// //         </div>
// //       </div>

// //       <Footer />
// //     </>
// //   );
// // };

// // export default Contact;

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import Navbar from './Navbar';
// import Footer from './Footer';
// import axios from 'axios';
// import { useAuth } from '../AuthContext';
// import PhoneInput from "react-phone-number-input";

// const Contact = () => {
//   const { apipath } = useAuth();
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     city: '',
//     description: '',
//   });
//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     if (!formData.name.trim()) {
//       newErrors.name = 'Name is required';
//     }

//     if (!formData.email.trim()) {
//       newErrors.email = 'Email is required';
//     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//       newErrors.email = 'Email is invalid';
//     }

//     if (!formData.phone.trim()) {
//       newErrors.phone = 'Phone number is required';
//     } else if (!/^\+?\d{10,15}$/.test(formData.phone)) {
//       newErrors.phone = 'Phone number is invalid';
//     }

//     if (!formData.city.trim()) {
//       newErrors.city = 'City is required';
//     }

//     if (!formData.description.trim()) {
//       newErrors.description = 'Description is required';
//     } else if (formData.description.length < 50) {
//       newErrors.description = 'Description must be greater than 50 characters';
//     }

//     setErrors(newErrors);

//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       try {
//         const response = await axios.post(apipath + '/contactUs/add', formData);
//         console.log('Response from API:', response.data);
//         navigate('/contactSubmit');
//       } catch (error) {
//         console.error('Error submitting form:', error);
//       }
//     }
//   };

//   const [contactData, setContactData] = useState(null);

//   useEffect(() => {
//     async function fetchContactData() {
//       try {
//         const response = await axios.get(apipath + '/homePage/details');
//         setContactData(response.data.homePage[0]);
//       } catch (error) {
//         console.error('Error fetching contact data:', error);
//       }
//     }
//     fetchContactData();
//   }, [apipath]);

//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid contact">
//         {contactData && (
//           <div className="contact-banner">
//             <h1>{contactData.contentHeading}</h1>
//             <p>{contactData.contentText}</p>
//           </div>
//         )}
//       </div>

//       <div className="container">
//         <div className="col-lg-10 mx-auto c-form">
//           <h1>Send us a message</h1>
//           <form onSubmit={handleSubmit}>
//             <div className="row">
//               <div className="col-lg-6">
//                 <div className="c-field">
//                   <label htmlFor="name">Name <span>*</span></label>
//                   <input
//                     type="text"
//                     name="name"
//                     value={formData.name}
//                     onChange={handleChange}
//                     placeholder="Your name"
//                     required
//                   />
//                   {errors.name && <div className="error">{errors.name}</div>}
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="c-field">
//                   <label htmlFor="email">Email <span>*</span></label>
//                   <input
//                     type="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     placeholder="Email Address"
//                     required
//                   />
//                   {errors.email && <div className="error">{errors.email}</div>}
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-lg-6 app-input">
//                 <div className="c-field">
//                   <label htmlFor="phone">Phone Number</label>
//                   {/* <input
//                     type="text"
//                     name="phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                     placeholder="+91"
//                     maxLength="10"
//                     autoComplete="off"
//                     onKeyPress={(event) => {
//                         if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
//                             event.preventDefault();
//                         }
//                     }}
//                     required
//                   /> */}

//                   <PhoneInput
//                     international
//                     defaultCountry="IN"
//                     className={`form-control ${errors.phone && "is-invalid"}`}
//                     value={formData.phone}
//                     onChange={(value) => handleChange(value, "phone")}
//                     inputProps={{
//                       id: "phone",
//                       name: "phone",
//                     }}
//                     maxLength="20"
//                   />
//                   {errors.phone && <div className="error">{errors.phone}</div>}
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="c-field">
//                   <label htmlFor="city">City </label>
//                   <input
//                     type="text"
//                     name="city"
//                     value={formData.city}
//                     onChange={handleChange}
//                     placeholder="City"
//                     required
//                   />
//                   {errors.city && <div className="error">{errors.city}</div>}
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-lg-12">
//                 <div className="c-field">
//                   <label htmlFor="description">Description</label>
//                   <textarea
//                     name="description"
//                     value={formData.description}
//                     onChange={handleChange}
//                     placeholder="Description..."
//                     minLength="50"
//                     required
//                   />
//                   {errors.description && <div className="error">{errors.description}</div>}
//                 </div>
//               </div>
//             </div>
//             <div className="contact-button">
//               <button type="submit">Send Message</button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Contact;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import { useAuth } from "../AuthContext";
import PhoneInput from "react-phone-number-input";

const Contact = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target || {};
    if (name) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\+?\d{10,15}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }

    if (!formData.city.trim()) {
      newErrors.city = "City is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(apipath + "/contactUs/add", formData);
        console.log("Response from API:", response.data);
        navigate("/contactSubmit");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const [contactData, setContactData] = useState(null);

  useEffect(() => {
    async function fetchContactData() {
      try {
        const response = await axios.get(apipath + "/homePage/details");
        setContactData(response.data.homePage[0]);
      } catch (error) {
        console.error("Error fetching contact data:", error);
      }
    }
    fetchContactData();
  }, [apipath]);

  return (
    <>
      <Navbar />

      <div className="container-fluid contact">
        {contactData && (
          <div className="contact-banner">
            <h1>{contactData.contentHeading}</h1>
            {/* <p>{contactData.contentText}</p> */}
            <div
                    dangerouslySetInnerHTML={{
                      __html: contactData.contentText,
                    }}
                  />
          </div>
        )}
      </div>

      <div className="container">
        <div className="col-lg-10 mx-auto c-form">
          <h1>Send me a message</h1>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="c-field">
                  <label htmlFor="name">
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your name"
                    required
                  />
                  {errors.name && <div className="error">{errors.name}</div>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="c-field">
                  <label htmlFor="email">
                    Email <span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    required
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 app-input">
                <div className="c-field">
                  <label htmlFor="phone">Phone Number</label>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    inputProps={{
                      id: "phone",
                      name: "phone",
                    }}
                    maxLength="15"
                  />
                  {errors.phone && <div className="error">{errors.phone}</div>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="c-field">
                  <label htmlFor="city">City </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="City"
                    required
                  />
                  {errors.city && <div className="error">{errors.city}</div>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="c-field">
                  <label htmlFor="description">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Description..."
                    // minLength="50"
                    // required
                  />
                </div>
              </div>
            </div>
            <div className="contact-button">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;

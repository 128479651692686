import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import moment from 'moment';

const BannerImagesDetails = () => {
    const { apipath, filepath } = useAuth();
    const [bannerImages, setBannerImages] = useState([]);
    const [deleteBannerImagesId, setDeleteBannerImagesId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);

    useEffect(() => {
        fetchBannerImages();
        if (!loading && bannerImages.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, bannerImages]);

    const handleShow = (id) => {
        setDeleteBannerImagesId(id);
        setShow(true);
    };

    const handleClose = () => {
        setDeleteBannerImagesId(null);
        setShow(false);
    };

    const fetchBannerImages = async () => {
        try {
            const response = await axios.get(apipath + '/banner-images/details');
            setBannerImages(response.data.bannerImages);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching bannerImages:', error);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!deleteBannerImagesId) return;

        try {
            setIsLoading(true);
            await axios.delete(apipath + `/banner-images/details/${deleteBannerImagesId}`);
            await fetchBannerImages(); // Refresh users after deletion
            setIsLoading(false);
            handleClose(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting blog:', error);
            setIsLoading(false);
        }
    };

    return (
        <>


            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Banner Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this banner image ?</p>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Banner images details</h5>
                        <div className="add-button">
                            <Link to="/add-banner-image"><button>+ Add Banner Images</button></Link>
                        </div>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Banner Image</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bannerImages.map((bannerImage, index) => (
                                            <tr key={bannerImage._id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <img
                                                        src={filepath + bannerImage.bannerImage || `${process.env.PUBLIC_URL}/imgs/Agp-Blog-Jan-3 1.png`}
                                                        width={90} height={90}
                                                        style={{ borderRadius: '35px' }}
                                                        alt={`${bannerImage.label?.toLowerCase() || 'default'}-img`}
                                                    />
                                                </td>
                                                <td>{moment(bannerImage.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>
                                                    <Link to={`/edit-banner-image/${bannerImage._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> {" "}
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(bannerImage._id)}></i>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BannerImagesDetails;
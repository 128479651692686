import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import PhoneInput from "react-phone-number-input";


const Details = () => {
  const { apipath, filepath } = useAuth();
  const { courseId } = useParams();
  const [courses, setCourses] = useState(null);
  
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
    courseId: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
  
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
  
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
  
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone is required";
    } else if (formData.phone.length < 10) {  // Minimum length for phone numbers
      newErrors.phone = "Phone number is invalid";
    }
  
    if (!formData.comment.trim()) {
      newErrors.comment = "Comment is required";
    } else if (formData.comment.length < 5) {
      newErrors.comment = "Comment must be at least 5 characters";
    }
  
    setErrors(newErrors);
    
    console.log('Validation errors:', newErrors); // Log validation errors
  
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log('Form submitted');
  
    if (validateForm()) {
      console.log('Validation passed');
      
      try {
        const response = await axios.post(apipath + "/course-enquiry/add", formData);
        console.log("Response from API:", response.data);
        navigate("/courses");
      } catch (error) {
        console.error("Error submitting form:", error.response?.data || error.message);
      }
    } else {
      console.log('Validation failed');
    }
  };
  


  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(apipath + `/course/details/${courseId}`);
        if (response.ok) {
          const data = await response.json();
          setCourses(data.course);
        } else {
          throw new Error("Failed to fetch course details");
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchBlogDetails();
  }, [courseId, apipath]);

  return (
    <>
      {/* NAVBAR */}

      <Navbar />

      {/* MAIN CONTENT */}
      <div className="row container-fluid my-5 ">
        <div className="col-lg-6 container details-container">
          {courses && (
            <>
              <h1>
                <span>{courses.courseName}</span>
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: courses.descriptionDetails }}
              />
            </>
          )}

          <div>
            {courses && (
              <>
                <div className="col-lg-8 mx-auto details-img">
                  <img
                    src={filepath + courses.coursePic}
                    width={250}
                    height={350}
                    style={{ borderRadius: "35px" }}
                  />
                </div>
              </>
            )}
          </div>

          {courses && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: courses.descriptionDetailsText,
                }}
              />
            </>
          )}

          <div className="detail-button d-flex justify-content-center mt-5">
            <Link to={`/price/${courseId}`}>
              <button>Proceed to Buy</button>
            </Link>
          </div>
        </div>

        <div className="col-lg-3 container">
          <div className="card p-4" style={{ backgroundColor: "#a952a3" }}>
            <h3 className="text-center mb-4" style={{ color: "#fff" }}>
              Drop Your Query
            </h3>
            <Form  onSubmit={handleSubmit}>
            <input type="text" name="courseId" value={courseId} onChange={handleChange} hidden/>

              {/* Name Input */}
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter your Name*"
                   name="name"
                  value={formData.name}
                  onChange={handleChange}
                  // required
                  style={{ padding: "10px" }}
                />
                {errors.name && <div className="error">{errors.name}</div>}
                
              </Form.Group>

              {/* Email Input */}
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Enter your email*"
                  name="email"
                    value={formData.email}
                    onChange={handleChange}
                  // required
                  style={{ padding: "10px" }}
                />
                {errors.email && <div className="error">{errors.email}</div>}
                
              </Form.Group>

              {/* Mobile Number Input */}
              <Form.Group controlId="formMobileNumber" className="mb-3 phn">
              <PhoneInput
                    international
                    defaultCountry="US"
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    inputProps={{
                      id: "phone",
                      name: "phone",
                    }}
                    maxLength="15"
                    // required
                  />
                  {errors.phone && <div className="error">{errors.phone}</div>}
                
              </Form.Group>

              {/* Message Input */}
              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Control
                  as="textarea"
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                  rows={3}
                  placeholder="Write an optional message..."
                  style={{ padding: "10px" }}
                />
                {errors.comment && <div className="error">{errors.comment}</div>}
                
              </Form.Group>

              {/* Submit Button */}
              <div className="text-center">
                <Button
                  variant="light"
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#fff",
                    color: "#000",
                  }}
                >
                  SUBMIT
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      {/* FOOTER */}

      <Footer />
    </>
  );
};

export default Details;

import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CourseAdd = () => {
  const [coursePicUrl, setCoursePicUrl] = useState(null);
  const [timeSlots, setTimeSlots] = useState([{ startTime: '', endTime: '' }]); // State for dynamic time slots
  const [descriptionPoints, setDescriptionPoints] = useState(['']); // State for multiple description points
  const [dateSlots, SetDateSlots] = useState(['']); // State for multiple description points

  const navigate = useNavigate();
  const { apipath, filepath } = useAuth();
  const [formData, setFormData] = useState({
    courseName: '',
    instructor: '',
    fee: '',
    description: '',
    courseExpire: '',
    coursePic: null,
    descriptionDetails: '',
    descriptionDetailsText: '',
    descriptionPrice: '',
    gst: '',
    startTimes: '',
    endTimes: '',
    classModel:'',
    priceOffer:'',
    startDate:'',
    endDate:'',
  });

  const handleUploadButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevDetails) => ({
        ...prevDetails,
        coursePic: file,
      }));
      const previewUrl = URL.createObjectURL(file);
      setCoursePicUrl(previewUrl);
    }
  };

  const handleChange = (e) => {
    const { name, value,type } = e.target;
    const updatedValue = type === 'number' ? parseFloat(value) : value;

  setFormData({
    ...formData,
    [name]: updatedValue,
  });
};

  const handleRegister = async (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append('courseName', formData.courseName);
    formdata.append('description', formData.description);
    formdata.append('instructor', formData.instructor);
    formdata.append('fee', formData.fee);
    formdata.append('courseExpire', formData.courseExpire);
    formdata.append('descriptionDetails', formData.descriptionDetails);
    formdata.append('descriptionDetailsText', formData.descriptionDetailsText);
    formdata.append('descriptionPrice', formData.descriptionPrice);
    formdata.append('coursePic', formData.coursePic);
    formdata.append('gst', formData.gst);
    formdata.append('startTimes', formData.startTimes);
    formdata.append('endTimes', formData.endTimes);
    formdata.append('priceOffer', formData.priceOffer);
    formdata.append('classModel', formData.classModel);
    formdata.append('timeSlots', JSON.stringify(timeSlots)); // Add timeSlots to the request
    formdata.append('descriptionPoints', JSON.stringify(descriptionPoints)); // Add descriptionPoints to the request
    formdata.append('dateSlots', JSON.stringify(dateSlots)); // Add descriptionPoints to the request

    try {
      const response = await axios.post(apipath + '/course/add-course',
        formdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 201) {
        iziToast.success({
          message: 'course added successfully',
          position: 'topCenter',
        });
        navigate('/coursesDetails');
      } else {
        iziToast.error({
          message: 'course addition failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error adding course:', error);
      iziToast.error({
        message: 'An error occurred',
        position: 'topCenter',
      });
    }
  };

  const handleEditorChange = (event, editor, field) => {
    const data = editor.getData();
    setFormData(prevDetails => ({
      ...prevDetails,
      [field]: data, // Update the specific field
    }));
  };

   // Handle adding/removing Time slot points
  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: '', endTime: '' }]); // Add a new time slot
  };

  const handleTimeSlotChange = (index, field, value) => {
    const updatedTimeSlots = timeSlots.map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    setTimeSlots(updatedTimeSlots); // Update the state with the modified time slot
  };

  const handleRemoveTimeSlot = (index) => {
    const updatedTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(updatedTimeSlots); // Remove the time slot at the given index
  };

   // Handle adding/removing description points
   const handleAddDescriptionPoint = () => {
    setDescriptionPoints([...descriptionPoints, '']);
  };

  const handleRemoveDescriptionPoint = (index) => {
    const updatedPoints = descriptionPoints.filter((_, i) => i !== index);
    setDescriptionPoints(updatedPoints);
  };

  const handleDescriptionPointChange = (index, value) => {
    const updatedPoints = descriptionPoints.map((point, i) =>
      i === index ? value : point
    );
    setDescriptionPoints(updatedPoints);
  };


  // Handle adding/removing description points
  const handleAddDateSlot = () => {
    SetDateSlots([...dateSlots, { startDate: '', endDate: '' }]); // Add a new date slot
  };

  const handleDateSlotChange = (index, field, value) => {
    const updatedDateSlots = dateSlots.map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    SetDateSlots(updatedDateSlots); // Update the state with the modified date slot
  };

  const handleRemoveDateSlot = (index) => {
    const updatedDateSlots = dateSlots.filter((_, i) => i !== index);
    SetDateSlots(updatedDateSlots); // Remove the date slot at the given index
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Add Course Information</h5>
            <div class="row nav-column">
              <div>
                <form className="row" onSubmit={handleRegister}>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items1" class="form-label">
                      Course Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter course Name"
                      name="courseName"
                      value={formData.courseName}
                      onChange={handleChange}
                      maxLength="100"
                      required
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                      Instructor
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter instructor name"
                      name="instructor"
                      value={formData.instructor}
                      onChange={handleChange}
                      maxLength="100"
                      required
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items1" class="form-label">
                    Course Price Offer
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter priceOffer"
                      name="priceOffer"
                      value={formData.priceOffer}
                      onChange={handleChange}
                      maxLength="5"
                      step="0.01"   
        min="0"
        max="99999"
        autoComplete="off"
        required
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                    Course Class Mode
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter class mode"
                      name="classModel"
                      value={formData.classModel}
                      onChange={handleChange}
                      maxLength="100"
                      required
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                      Course Fee
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter course fee"
                      name="fee"
                      value={formData.fee}
                      onChange={handleChange}
                      maxLength="5"
                      // autoComplete="off"
                      // onKeyPress={(event) => {
                      //   if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      step="0.01"   
        min="0"
        max="99999"
        autoComplete="off"
        required
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                      Course GST
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter course gst"
                      name="gst"
                      value={formData.gst}
                      onChange={handleChange}
                      maxLength="5"
                      step="0.01"   
        min="0"
        max="99999"
        autoComplete="off"
        required
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-item2" className="form-label">
                      Course Start Time
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter time slot"
                      name="startTimes"
                      value={formData.startTimes}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-item2" className="form-label">
                      Course End Time
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter time slot"
                      name="endTimes"
                      value={formData.endTimes}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                      Course Expire
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter course expire"
                      name="courseExpire"
                      value={formData.courseExpire}
                      onChange={handleChange}
                      maxLength="50"
                    //   required
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label for="nav-items1" className="form-label">
                      Designation
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter course description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      maxLength="1000"
                      required
                    ></textarea>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items2" className="form-label">Description Details</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={formData.descriptionDetails}
                      onChange={(event, editor) => handleEditorChange(event, editor, 'descriptionDetails')}
                      name="descriptionDetails"
                    />
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items2" className="form-label">Description Details Text</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={formData.descriptionDetailsText}
                      onChange={(event, editor) => handleEditorChange(event, editor, 'descriptionDetailsText')}
                      name="descriptionDetailsText"
                    />
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items2" className="form-label">Description Price</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={formData.descriptionPrice}
                      onChange={(event, editor) => handleEditorChange(event, editor, 'descriptionPrice')}
                      name="descriptionPrice"
                    />
                  </div>

                  <div className="change-profile pt-4 pb-4">
                    <img
                      src={coursePicUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
                      width={150} height={100}
                      style={{ borderRadius: '35px' }}
                      alt="profile-img"
                      onClick={handleUploadButtonClick}
                    />
                    <h6>Course Image</h6>
                    <input
                      type="file"
                      id="fileInput"
                      name="coursePic"
                      onChange={handleFileChange}
                      // style={{ display: 'none' }}
                      // required
                    />
                  </div>

                  {/* Time Slots Section */}
                  {/* <div className="col-lg-12 mb-3">
                    <label className="form-label">Course Time Slots</label>
                    {timeSlots.map((slot, index) => (
                      <div key={index} className="row mb-3">
                        <div className="col-lg-5">
                          <input
                            type="time"
                            className="form-control"
                            placeholder="Start Time"
                            value={slot.startTime}
                            onChange={(e) => handleTimeSlotChange(index, 'startTime', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="time"
                            className="form-control"
                            placeholder="End Time"
                            value={slot.endTime}
                            onChange={(e) => handleTimeSlotChange(index, 'endTime', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-2">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveTimeSlot(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="btn btn-primary" onClick={handleAddTimeSlot}>
                      Add Time Slot
                    </button>
                  </div> */}
                  
                     {/* slotDate Points Section */}
                    <div className="col-lg-12 mb-3">
                    <label className="form-label">Course Date Slots</label>
                    {dateSlots.map((slot, index) => (
                      <div key={index} className="row mb-3">
                        <div className="col-lg-5">
                          <input
                            type="Date"
                            className="form-control"
                            placeholder="Start Date"
                            value={slot.startDate}
                            onChange={(e) => handleDateSlotChange(index, 'startDate', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="Date"
                            className="form-control"
                            placeholder="End Date"
                            value={slot.endDate}
                            onChange={(e) => handleDateSlotChange(index, 'endDate', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-2">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveDateSlot(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="btn btn-primary" onClick={handleAddDateSlot}>
                      Add Date Slot
                    </button>
                  </div>

                   {/* Description Points Section */}
                <div className="col-lg-12 mb-3">
                    <label className="form-label">Description Points</label>
                    {descriptionPoints.map((point, index) => (
                      <div key={index} className="row mb-3">
                        <div className="col-lg-10">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Description Point ${index + 1}`}
                            value={point}
                            onChange={(e) => handleDescriptionPointChange(index, e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-2 d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveDescriptionPoint(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleAddDescriptionPoint}
                    >
                      Add Description Point
                    </button>
                  </div>
                  
                  <div className="">
                    <button type="submit" className="btn btn-success">
                      Add Course
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseAdd;

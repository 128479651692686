import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import iziToast from 'izitoast';

const Profile = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    dob: "",
    gender: "",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(apipath + `/users/details/${userId}`);
        const userData = response.data.user;
  
        // Check if the dob exists and convert it to the appropriate format (YYYY-MM-DD) for the input field
        if (userData.dob) {
          userData.dob = new Date(userData.dob).toISOString().split('T')[0];  // format as 'YYYY-MM-DD'
        }
  
        setFormData(userData);
        console.log('userData : ', userData);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
  
    fetchUserData();
  }, [userId]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
           
    // If dob exists, ensure it's formatted correctly before sending to the backend
  if (formData.dob) {
    formData.dob = new Date(formData.dob).toISOString().split('T')[0];
  }
    try {
      // Create a payload with properly formatted data
      const updatedData = {
        ...formData,
        dob: formData.dob ? formData.dob.split("T")[0] : "", 
        gender: formData.gender || "none"
      };
  
      const response = await axios.put(apipath + `/users/details/${userId}`, updatedData);
  
      if (response.status === 200) {
        iziToast.success({
          message: "User update successful",
          position: "topCenter",
        });
        navigate("/");
      } else {
        iziToast.error({
          message: "User update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      ...(name === "dob" && { dob: value }),
    }));
  };
  
  return (
    <>
      <Navbar login={true} />
      <div className="container">
        <div className="col-lg-8 mx-auto p-4 profile-form">
          <h1>Profile Settings</h1>
          <p>These are your personal details, You can edit these here</p>
          <form onSubmit={handleSubmit}>
            <div className="p-block row">
              <div className="col-lg-6">
                <div className="p-field">
                  <label htmlFor="fname">User Name</label>
                  <div class="input-group p-group">
                    <div class="input-group-prepend p-prepend">
                      <span class="input-group-text p-group-text" id="basic-addon1">
                        <i className="bi bi-person"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control p-control"
                      placeholder="First Name"
                      aria-label="fname"
                      aria-describedby="basic-addon1"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-field">
                  <label htmlFor="lname">Last Name</label>
                  <div class="input-group p-group">
                    <div class="input-group-prepend p-prepend">
                      <span class="input-group-text p-group-text" id="basic-addon1">
                        <i className="bi bi-person"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control p-control"
                      placeholder="Last Name"
                      aria-label="lname"
                      aria-describedby="basic-addon1"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-block row">
              <div className="col-lg-6">
                <div className="p-field">
                  <label htmlFor="phone">Phone Number</label>
                  <div class="input-group p-group">
                    <div class="input-group-prepend p-prepend">
                      <span class="input-group-text p-group-text" id="basic-addon1">
                        <i className="bi bi-telephone"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control p-control"
                      placeholder="00000 00000"
                      aria-label="phone"
                      aria-describedby="basic-addon1"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-field">
                  <label htmlFor="email">Email ID</label>
                  <div class="input-group p-group">
                    <div class="input-group-prepend p-prepend">
                      <span class="input-group-text p-group-text" id="basic-addon1">
                        <i className="bi bi-envelope"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control p-control"
                      placeholder="abc@xyz.com"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-block row">
              <div className="col-lg-6">
                <div className="p-field">
                  <label htmlFor="gender">Gender</label>
                  <div class="input-group p-group">
                    <select name="gender" id="gender" value={formData.gender} onChange={handleChange}>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                      <option value="none">Prefer not to say</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-field">
                  <label htmlFor="dob">Date of Birth</label>
                  <div class="input-group p-group">
                    <input
                      type="date"
                      className="form-control p-control"
                      aria-label="dob"
                      aria-describedby="basic-addon1"
                      name="dob"
                      value={formData.dob}
                      onChange={handleChange}
                    />

                  </div>
                </div>
              </div>
            </div>
            <div className="p-block row">
              <div className="p-field">
                <label htmlFor="address">Address</label>
                <div class="input-group p-group">
                  <div class="input-group-prepend p-prepend">
                    <span class="input-group-text p-group-text" id="basic-addon1">
                      <i className="bi bi-house-door"></i>
                    </span>
                  </div>
                  <textarea
                    type="text"
                    class="form-control p-control"
                    placeholder="Your Address"
                    aria-label="address"
                    aria-describedby="basic-addon1"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="p-buttons">
              <Link className="b1" to={`/`}>Cancel</Link>
              <button className="b2">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;

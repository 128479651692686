// import React, { useEffect, useState } from "react";
// import SideBar from "./SideBar";
// import Navbar from "./Navbar";
// import { useNavigate, useParams } from "react-router-dom";
// import iziToast from "izitoast";
// import { useAuth } from "../../AuthContext";
// import axios from "axios";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


// const BlogsEdit = () => {
//   const [blogImageUrl, setBlogImageUrl] = useState(null);
//   const [blogContents, setBlogContents] = useState('');
//   const { blogId } = useParams();

//   const navigate = useNavigate();
//   const { apipath, filepath, logout } = useAuth();
//   const [formData, setFormData] = useState({
//     blogHeading: '',
//     blogText: '',
//     blogContent: '',
//     blogTeacherName: '',
//     blogTime: '',
//     blogScheduleMonth: '',
//     blogImage: null,
// });
//   const token = localStorage.getItem('token');

//   const handleUploadButtonClick = () => {
//     document.getElementById('fileInput').click();
// };

// const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//         setFormData((prevDetails) => ({
//             ...prevDetails,
//             blogImage: file,
//         }));
//         const previewUrl = URL.createObjectURL(file);
//         setBlogImageUrl(previewUrl);
//     }
// };

// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setFormData({
//       ...formData,
//       [name]: value,
//   });
// };

//   useEffect(() => {
//     fetchBlogEdit();
//   }, []);

//   const fetchBlogEdit = async () => {

//     try {
//       const response = await axios.get(
//         apipath + `/blog/details/${blogId}`
//       );
//       setFormData(response.data.blog);
//     } catch (error) {
//       console.error("Error fetching blog Details:", error);
//     }
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();

//         const formdata = new FormData();
//         formdata.append('blogHeading', formData.blogHeading);
//         formdata.append('blogText', formData.blogText);
//         formdata.append('blogContent', formData.blogContent);
//         formdata.append('blogTeacherName', formData.blogTeacherName);
//         formdata.append('blogTime', formData.blogTime);
//         formdata.append('blogScheduleMonth', formData.blogScheduleMonth);
//         formdata.append('blogImage', formData.blogImage);

//     try {
//       const response = await axios.put(
//         apipath + `/blog/details/${blogId}`,
//         formdata,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );

//       if (response.status === 200) {
//         iziToast.success({
//           message: "Blog update successful",
//           position: "topCenter",
//         });
//         navigate("/blogsDetails");
//       } else {
//         iziToast.error({
//           message: "Blog update failed",
//           position: "topCenter",
//         });
//       }
//     } catch (error) {
//       console.error("Error updating course details:", error);
//       iziToast.error({
//         message: "An error occurred",
//         position: "topCenter",
//       });
//     }
//   };

//   const handleEditorChange = (event, editor) => {
//     const data = editor.getData();
//     setBlogContents(data);
//     setFormData(prevDetails => ({
//         ...prevDetails,
//         blogContent: data,
//     }));
// }


//   return (
//     <>
//       <SideBar />
//       <Navbar />

//       <section className="home">
//                 <div className="toggle-sidebar" style={{ display: "none" }}>
//                     <i className="bi bi-menu"></i>
//                 </div>

//                 <div className="container pt-3">
//                     <div className="row top-barcolumn">
//                         <h5>Edit Blog Information</h5>
//                         <div className="row nav-column">
//                             <div>
//                                 <form className="row" onSubmit={handleUpdate}>
//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items1" className="form-label">
//                                             Blog Heading
//                                         </label>
//                                         <textarea
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog heading"
//                                             name="blogHeading"
//                                             value={formData.blogHeading}
//                                             onChange={handleChange}
//                                             maxLength="100"
//                                             required
//                                         />
//                                     </div>
//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items2" className="form-label">
//                                             Blog Text
//                                         </label>
//                                         <textarea
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item2"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog text"
//                                             name="blogText"
//                                             value={formData.blogText}
//                                             onChange={handleChange}
//                                             maxLength="500"
//                                             required
//                                         ></textarea>
//                                     </div>
//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items1" className="form-label">
//                                             Blog Teacher Name
//                                         </label>
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog teacher name"
//                                             name="blogTeacherName"
//                                             value={formData.blogTeacherName}
//                                             onChange={handleChange}
//                                             maxLength="50"
//                                             required
//                                         />
//                                     </div>
//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items1" className="form-label">
//                                             Blog Time
//                                         </label>
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog time 2:30pm"
//                                             name="blogTime"
//                                             value={formData.blogTime}
//                                             onChange={handleChange}
//                                             maxLength="50"
//                                             required
//                                         />
//                                     </div>

//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items2" className="form-label">
//                                             Blog Schedule Month
//                                         </label>
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item2"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter blog schedule month 02 June 2024"
//                                             name="blogScheduleMonth"
//                                             value={formData.blogScheduleMonth}
//                                             onChange={handleChange}
//                                             maxLength="50"
//                                             required
//                                         />
//                                     </div>

//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items2" className="form-label"></label>
//                                         <CKEditor
//                                             editor={ClassicEditor}
//                                             data={blogContents}
//                                             onChange={handleEditorChange}
//                                             name="blogContent"
//                                         />
//                                     </div>

//                                     <div className="change-profile pt-4 pb-4">
//                                         <img
//                                             src={blogImageUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
//                                             width={150} height={100}
//                                             style={{ borderRadius: '35px' }}
//                                             alt="profile-img"
//                                             onClick={handleUploadButtonClick}
//                                         />
//                                         <h6>Product Image</h6>
//                                         <input
//                                             className="form-control mt-4"
//                                             type="file"
//                                             id="fileInput"
//                                             name="blogImage"
//                                             onChange={handleFileChange}
//                                             style={{ display: "none" }} // Hide the file input
//                                         />
//                                     </div>

//                                     <div>
//                                         <button type="submit" className="btn btn-success">
//                                             Update Blog
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default BlogsEdit;

import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const BlogsEdit = () => {
  const [blogImageUrl, setBlogImageUrl] = useState(null);
  const [teacherImageUrl, setTeacherImageUrl] = useState(null);
  const [blogContents, setBlogContents] = useState('');
  const { blogId } = useParams();
  const navigate = useNavigate();
  const { apipath, filepath, logout } = useAuth();
  const [formData, setFormData] = useState({
    blogHeading: '',
    blogText: '',
    blogContent: '',
    blogTeacherName: '',
    blogTime: '',
    blogScheduleMonth: '',
    blogImage: null,
    teacherImage: null,
  });
  const token = localStorage.getItem('token');

  const handleUploadButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
        const previewUrl = URL.createObjectURL(file);

        if (type === 'blogImage') {
            setFormData((prevDetails) => ({
                ...prevDetails,
                blogImage: file,
            }));
            setBlogImageUrl(previewUrl);
        } else if (type === 'teacherImage') {
            setFormData((prevDetails) => ({
                ...prevDetails,
                teacherImage: file,
            }));
            setTeacherImageUrl(previewUrl);
        }
    }
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });
  };

  useEffect(() => {
    fetchBlogEdit();
  }, []);

  const fetchBlogEdit = async () => {
    try {
      const response = await axios.get(apipath + `/blog/details/${blogId}`);
      const blogData = response.data.blog;
      setFormData(blogData);

      // Set CKEditor content
      setBlogContents(blogData.blogContent || '');

      // Set the blog image URL if it exists
      if (blogData.blogImage) {
        setBlogImageUrl(filepath + '/' + blogData.blogImage);
      }
      
       // Set the blog image URL if it exists
       if (blogData.teacherImage) {
        setTeacherImageUrl(filepath + '/' + blogData.teacherImage);
      }
    } catch (error) {
      console.error("Error fetching blog Details:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append('blogHeading', formData.blogHeading);
    formdata.append('blogText', formData.blogText);
    formdata.append('blogContent', formData.blogContent);
    formdata.append('blogTeacherName', formData.blogTeacherName);
    formdata.append('blogTime', formData.blogTime);
    formdata.append('blogScheduleMonth', formData.blogScheduleMonth);
    formdata.append('blogImage', formData.blogImage);
    formdata.append('teacherImage', formData.teacherImage);

    try {
      const response = await axios.put(
        apipath + `/blog/details/${blogId}`,
        formdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        iziToast.success({
          message: "Blog update successful",
          position: "topCenter",
        });
        navigate("/blogsDetails");
      } else {
        iziToast.error({
          message: "Blog update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error updating course details:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setBlogContents(data);
    setFormData(prevDetails => ({
        ...prevDetails,
        blogContent: data,
    }));
  }

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Blog Information</h5>
            <div className="row nav-column">
              <div>
                <form className="row" onSubmit={handleUpdate}>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Blog Heading
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter blog heading"
                      name="blogHeading"
                      value={formData.blogHeading}
                      onChange={handleChange}
                      maxLength="100"
                      required
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items2" className="form-label">
                      Blog Text
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter blog text"
                      name="blogText"
                      value={formData.blogText}
                      onChange={handleChange}
                      maxLength="500"
                      required
                    ></textarea>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Blog Teacher Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter blog teacher name"
                      name="blogTeacherName"
                      value={formData.blogTeacherName}
                      onChange={handleChange}
                      maxLength="50"
                      required
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Blog Time
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter blog time 2:30pm"
                      name="blogTime"
                      value={formData.blogTime}
                      onChange={handleChange}
                      maxLength="50"
                      required
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items2" className="form-label">
                      Blog Schedule Month
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter blog schedule month 02 June 2024"
                      name="blogScheduleMonth"
                      value={formData.blogScheduleMonth}
                      onChange={handleChange}
                      maxLength="50"
                      required
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items2" className="form-label"></label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={blogContents}
                      onChange={handleEditorChange}
                      name="blogContent"
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                  
                      {blogImageUrl && (
                        <img
                          src={blogImageUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
                          width={150} height={100}
                          style={{ borderRadius: '35px' }}
                          alt="Blog Preview"
                          className="img-thumbnail mt-3"  
                          onClick={() => document.getElementById('blogImageInput').click()}
                        />
                      )}
                      <h6>Blog Image</h6>
                      <input
                        type="file"
                          id="blogImageInput"
                    name="blogImage"
                        accept="image/*"
                        // style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, 'blogImage')}
                      />
                    </div>

                    <div className="col-lg-12 mb-3">
                    
                      {teacherImageUrl && (
                        <img
                          src={teacherImageUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
                          width={150} height={100}
                          style={{ borderRadius: '35px' }}
                          alt="Blog Preview"
                          className="img-thumbnail mt-3"  
                          onClick={() => document.getElementById('teacherImageInput').click()}
                        />
                      )}
                      <h6>Blog Teacher Image</h6>
                      <input
                        type="file"
                        id="teacherImageInput"
                        accept="image/*"
                         name="teacherImage"
                        // style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, 'teacherImage')}
                      />
                    </div>
                  <div>
                  <button type="submit" className="btn btn-success">
                      Update Blog
                    </button>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsEdit;

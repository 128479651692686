import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";
import Slider from "react-slick";

const Landing = () => {
  const { apipath, filepath } = useAuth();
  const [showItem, setShowItem] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const { isLoggedIn } = useAuth();
  const handleClick = (index) => {
    setShowItem(showItem === index ? null : index);
  };

  const [homePageData, setHomePageData] = useState(null);
  const [faqData, setfaqData] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);

  useEffect(() => {
    const fetchFaqEdit = async () => {
      try {
        const response = await axios.get(apipath + `/faqs/details`);
        setfaqData(response.data.faqs);
      } catch (error) {
        console.error("Error fetching faqs Details:", error);
      }
    };
    fetchFaqEdit();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    const fetchBannerEdit = async () => {
      try {
        const response = await axios.get(apipath + `/banner-images/details`);
        setBannerImages(response.data.bannerImages);
      } catch (error) {
        console.error("Error fetching bannerImages Details:", error);
      }
    };
    fetchBannerEdit();
  }, []);

  useEffect(() => {
    async function fetchHomePageData() {
      try {
        const response = await axios.get(apipath + "/homePage/details");
        setHomePageData(response.data.homePage[0]);
      } catch (error) {
        console.error("Error fetching home page data:", error);
      }
    }
    fetchHomePageData();
  }, []);

  useEffect(() => {
    async function fetchCourseData() {
      try {
        const response = await axios.get(apipath + "/course/details");
        const courses = response.data.course.map((course) => ({
          ...course,
          descriptionPoints: JSON.parse(course.descriptionPoints[0] || "[]"),
          timeSlots: JSON.parse(course.timeSlots[0] || "[]"),
        }));
        setCourseData(courses);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    }
    fetchCourseData();
  }, []);

  useEffect(() => {
    const fetchBannerEdit = async () => {
      try {
        const response = await axios.get(apipath + `/banner-images/details`);
        setBannerImages(response.data.bannerImages);
      } catch (error) {
        console.error("Error fetching bannerImages Details:", error);
      }
    };
    fetchBannerEdit();
  }, []);

  useEffect(() => {
    async function fetchHomePageData() {
      try {
        const response = await axios.get(apipath + "/homePage/details");
        setHomePageData(response.data.homePage[0]);
      } catch (error) {
        console.error("Error fetching home page data:", error);
      }
    }
    fetchHomePageData();
  }, []);

  useEffect(() => {
    async function fetchCourseData() {
      try {
        const response = await axios.get(apipath + "/course/details");
        const courses = response.data.course.map((course) => ({
          ...course,
          descriptionPoints: JSON.parse(course.descriptionPoints), // Parsing descriptionPoints here
        }));
        setCourseData(courses);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    }
    fetchCourseData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />

      {/* BANNER */}

      <div className="container l-banner">
        <div className="row l-row">
          <div className="col-lg-6">
            <div className="lb-content">
              {homePageData && (
                <>
                  <h1>{homePageData.courseHeading}</h1>
                  <h3>{homePageData.courseSubHeading}</h3>
                  <p>{homePageData.courseText}</p>
                  <div className="lb-button">
                    <Link to="/courses" className="text-decoration-none">
                      <button>View Training Schedule</button>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6 lb-img">
            {/*--------------- UPDATED PART ---------------------- */}

            <Slider {...settings}>
              {bannerImages.map((bannerImage, index) => (
                <div key={index}>
                  <img
                    src={filepath + bannerImage.bannerImage}
                    alt={`Banner ${index + 1}`}
                    width={500}
                    height={450}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      {/* COURSES */}

      <div className="container-fluid course py-5 mt-5">
        <div className="container">
          <div className="course-heading pb-4 col-lg-6 mx-auto">
            <h1>
              Our <span>Courses</span>
            </h1>
            <p>
              Empower Your Leadership Journey: Unleash the Full Potential of
              Scrum Mastery with Our Comprehensive Course
            </p>
          </div>

          {/* <div className="course-row row">
            {courseData &&
              courseData.map((course) => (
                <div key={course._id} className="col-lg-6">
                  <div className="course-card">
                    <h1>
                      {course.courseName}
                      <span> Course Overview</span>
                    </h1>
                    <p>{course.description}</p>
                    <div className="row">
                      {course.descriptionPoints &&
                        course.descriptionPoints.map((point, index) => (
                          <div className="col-lg-6" key={index}>
                            <div className="course1-card">
                              <img src="imgs/scrum-icon.png" alt="" />
                              <p>{point}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="course-button">
                      <Link
                        className="text-decoration-none"
                        to={`/details/${course._id}`}
                      >
                        <button>Get Started</button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div> */}
          <div className="course-row row">
            {courseData &&
              courseData.map((course) => (
                <div key={course._id} className="col-lg-6">
                  <div className="course-card">
                    <h1>{course.courseName}</h1>
                    <p>{course.description}</p>
                    <div className="row">
                      {course.descriptionPoints &&
                        course.descriptionPoints.map((point, index) => (
                          <div className="col-lg-6" key={index}>
                            <div className="course1-card">
                              <img src="imgs/scrum-icon.png" alt="" />
                              <p>{point}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="course-button">
                      <Link
                        className="text-decoration-none"
                        to={`/details/${course._id}`}
                      >
                        <button>Get Started</button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* ABOUT */}

      <div className="container about py-5">
        <div className="row">
          {homePageData && (
            <>
              <div className="col-lg-6">
                <div className="about-left">
                  <h1 className="py-4">
                    About <span>{homePageData.aboutHeading}</span>
                  </h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homePageData.aboutText1,
                    }}
                  />
                  <button>
                    <Link
                      to="https://www.linkedin.com/in/meiyappanmuthu/"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin"></i>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-img">
                  <img
                    src={filepath + homePageData.aboutImage}
                    alt={"image not found"}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* WHY US */}

      {/* ------------------------UPDATED------------------------  */}

      <div className="container about py-2">
        {homePageData && (
          <>
            <div className="row">
              <div className="col-lg-5">
                <div className="about-img">
                  <img
                    src={filepath + homePageData.whyUsImage}
                    alt={"image not found"}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-left why-right">
                  <div
                    dangerouslySetInnerHTML={{ __html: homePageData.whyUsText }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* TRAINING SCHEDULE */}

      <div className="container t-container">
        <div className="t-heading col-lg-6 mx-auto">
          <h1>Training Schedule</h1>
          <p>
            Empower Your Leadership Journey: Unleash the Full Potential of Scrum
            Mastery with Our Comprehensive Course
          </p>
        </div>
        <div className="t-cards row">
          {courseData &&
            courseData.map((course) => (
              <div className="col-lg-6">
                <div className="t-card">
                  <div className="t-left pt-2">
                    <h2>{course.courseName}</h2>
                    {/* <h4>
                          {new Date(`1970-01-01T${course.startTimes}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}{" "}
                          to{" "}
                          {new Date(`1970-01-01T${course.endTimes}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} {" "} {"IST"}
                        </h4>
                        <p>
                          Instructor:<span> {course.instructor}</span>
                        </p> */}
                    <h6>
                      {new Date(
                        `1970-01-01T${course.startTimes}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      to{" "}
                      {new Date(
                        `1970-01-01T${course.endTimes}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      {"IST"}
                    </h6>
                    <h6>
                      Instructor: <span>{course.instructor}</span>
                    </h6>
                    <h6>
                      Class Mode: <span>{course.classModel}</span>
                    </h6>
                    <h6>
                      <span>Delivered online from Sydney, Australia</span>
                    </h6>
                  </div>
                  <div className="t-right">
                    <Link
                      className="text-decoration-none"
                      to={`/details/${course._id}`}
                    >
                      <button>Enroll Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* FREQUENTLY ASKED QUESTIONS */}

      <div className="container-fluid faq">
        <div className="f-heading col-lg-6 mx-auto">
          <h1>Frequently Asked Questions</h1>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            varius enim in eros elementum tristique.
          </p> */}
        </div>
        <div className="row">
          <div className="mx-auto col-lg-10 faq-c row">
            <div className="col-lg-3"></div>
            <div className="col-lg-12">
              {faqData.map((faq, index) => (
                <div className="faq-content" key={index}>
                  <div className="f-card">
                    <div className="d-flex justify-content-between">
                      <h3>{faq.heading}</h3>
                      <i
                        className="bi bi-chevron-down"
                        onClick={() => handleClick(index)}
                      ></i>
                    </div>
                    {showItem === index && (
                      <div>
                        <p>{faq.text}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Landing;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

import Navbar from "./Navbar";
import Footer from "./Footer";
import { useAuth } from "../AuthContext";
import iziToast from "izitoast";
import { Border } from "react-bootstrap-icons";

const Login = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    firstName: "",
    phone: "",
    email: "",
    dob: "",
    password: "",
    gender: "",
    address: "",
    agreeToTerms: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(apipath + "/users/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.status === 201) {
        const savedUserId = data.userId;
        const emailId = data.email;

        iziToast.success({
          title: "Success",
          message: data.message || "Registration successful",
          position: "topCenter",
          timeout: 2000,
        });
        navigate(
          `/otp-verify/${savedUserId}?email=${encodeURIComponent(emailId)}`
        );
      } else if (response.status === 400) {
        iziToast.error({
          title: "Error",
          message:
            data.message ||
            "Email or Mobile No. already exists. Please try another email or mobile no.",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          title: "Error",
          message: "Registration failed. Please try again later.",
          position: "topCenter",
        });
      }
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Registration failed. Please try again later.",
        position: "topCenter",
      });
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  return (
    <>
      {/* Navbar component */}
      <Navbar />

      <div className="container-fluid login-main">
        <div className="col-lg-6 login-c">
          <div className="col-lg-8 mx-auto login">
            <h1>Welcome Back</h1>
            <h3>Signup to continue learning</h3>
            <form onSubmit={handleSubmit}>
              <div className="login-form">
                <label htmlFor="name">User Name</label>
                <input
                  type="text"
                  placeholder="Enter your user name"
                  name="name"
                  value={formData.name} // Bind value to state
                  onChange={handleChange}
                  required
                />
                <label htmlFor="firstName">First name</label>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  value={formData.firstName} // Bind value to state
                  onChange={handleChange}
                  required
                />
                <label htmlFor="lastName">Surname</label>
                <input
                  type="text"
                  placeholder="Enter your sur name"
                  name="lastName"
                  value={formData.lastName} // Bind value to state
                  onChange={handleChange}
                  // required
                />
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email} // Bind value to state
                  onChange={handleChange}
                  required
                />
                <div className=" phn ">
                  <label htmlFor="phone">Phone number</label>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    inputProps={{
                      id: "phone",
                      name: "phone",
                    }}
                    maxLength="15"
                    required
                  />
                </div>
                <label htmlFor="password">Password</label>
                <div class="input-group l-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control l-control"
                    placeholder="Password"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                    name="password"
                    value={formData.password} // Bind value to state
                    onChange={handleChange}
                    required
                  />
                  <div class="input-group-prepend l-prepend">
                    <span
                      class="input-group-text l-group-text"
                      id="basic-addon1"
                      onClick={togglePasswordVisibility}
                    >
                      <i className="bi bi-eye"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="login-agree1">
                <input
                  type="checkbox"
                  name="agreeToTerms"
                  checked={formData.agreeToTerms} // Bind checked attribute to state
                  onChange={handleChange}
                  required
                />
                <span className="agree">
                  I agree to the terms and conditions
                </span>
              </div>
              <div className="login-button">
                <button type="submit">Sign up</button>
              </div>
              <div className="acc">
                <p>
                  Already have an account ?{" "}
                  <span>
                    <Link to="/signin">Sign in</Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 login-image">
          <img src="imgs/loginImage.jpeg" alt="" />
        </div>
      </div>

      {/* Footer component */}
      <Footer classes={true} />
    </>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import Navbar from "./Navbar";

const FooterContentEdit = () => {
  const { apipath } = useAuth();
  const [activeTab, setActiveTab] = useState("privacy");

  const [footerContentDetails, setFooterContentDetails] = useState({
    termsContent: "",
    policyContent: "",
  });

  const [privacyContent, setPrivacyContent] = useState('');
  const [termsContent, setTermsContent] = useState('');

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  
  useEffect(() => {
    fetchFooterContentDetails();
  }, []);

  const fetchFooterContentDetails = async () => {
    try {
      const response = await axios.get(
        apipath + "/termsPolicy/details/66a9ca9d0698e17df07239aa"
      );
      const { termsContent, policyContent } = response.data.termsPolicy;
      setFooterContentDetails(response.data.termsPolicy);
      setPrivacyContent(policyContent);
      setTermsContent(termsContent);
    } catch (error) {
      console.error("Error fetching footer content:", error);
    }
  };


const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${apipath}/termsPolicy/details/66a9ca9d0698e17df07239aa`,
        footerContentDetails
      );
      if (response.status === 200) {
        iziToast.success({
          message: "Footer content details updated successfully!",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Footer content details updated failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error updating home page details:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    if (activeTab === "privacy") {
      setPrivacyContent(data);
      setFooterContentDetails(prevDetails => ({
        ...prevDetails,
        policyContent: data,
      }));
    } else {
      setTermsContent(data);
      setFooterContentDetails(prevDetails => ({
        ...prevDetails,
        termsContent: data,
      }));
    }
  };

  return (
    <>
       <SideBar />
       <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row home-toggletab">
            <h4>Edit Footer Information</h4>
            <div className="col-lg-11 hometab-column">

              <div className="tab">
                <button
                  className={`tablinks ${activeTab === "privacy" && "active"}`}
                  onClick={() => openTab("privacy")}
                  id="privacy-button"
                >
                  Privacy Policy
                </button>
                <button
                  className={`tablinks ${activeTab === "terms" && "active"}`}
                  onClick={() => openTab("terms")}
                  id="terms-button"
                >
                  Terms & Conditions
                </button>
              </div>

              <div
                id="privacy"
                className={`tabcontent ${activeTab === "privacy" ? "active" : ""}`}
              >
                <div className="season_content">
                  <form>
                    <span className="nav-link-name">Privacy Policy</span>
                    <CKEditor
                      editor={ClassicEditor}
                      data={privacyContent}
                      onChange={handleEditorChange}
                      name="policyContent"
                    />
                    <button type="button" className="btn btn-success" onClick={handleUpdate}>
                      Update
                    </button>
                  </form>
                </div>
              </div>

              <div
                id="terms"
                className={`tabcontent ${activeTab === "terms" ? "active" : ""}`}
              >
                <div className="season_content">
                  <form>
                    <span className="nav-link-name">Terms & Conditions</span>
                    <CKEditor
                      editor={ClassicEditor}
                      data={termsContent}
                      onChange={handleEditorChange}
                      name="termsContent"
                    />
                    <button type="button" className="btn btn-success" onClick={handleUpdate}>
                      Update
                    </button>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterContentEdit;

import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CourseEdit = () => {
    const [coursePicUrl, setCoursePicUrl] = useState(null);
    const { courseId } = useParams();

    const navigate = useNavigate();
    const { apipath, filepath, logout } = useAuth();
     const [timeSlots, setTimeSlots] = useState([{ startTime: '', endTime: '' }]);
    const [descriptionPoints, setDescriptionPoints] = useState(['']);
    const [dateSlots, SetDateSlots] = useState(['']); // State for multiple description points

    const [formData, setFormData] = useState({
        courseName: '',
        instructor: '',
        fee: '',
        description: '',
        timeSlot: '',
        courseExpire: '',
        coursePic: null,
        descriptionDetails: '',
        descriptionDetailsText: '',
        descriptionPrice: '',
        startTimes: '',
        endTimes: '',
        gst: '',
        classModel:'',
        priceOffer:'',
    startDate:'',
    endDate:'',
    });

    const handleUploadButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData((prevDetails) => ({
                ...prevDetails,
                coursePic: file,
            }));
            const previewUrl = URL.createObjectURL(file);
            setCoursePicUrl(previewUrl);
        }
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const updatedValue = type === 'number' ? parseFloat(value) : value;

        setFormData({
            ...formData,
            [name]: updatedValue,
        });
    };

    useEffect(() => {
        fetchCourseEdit();
    }, []);

    const fetchCourseEdit = async () => {
        try {
            const response = await axios.get(apipath + `/course/details/${courseId}`);
            const coursesData = response.data.course;
            setFormData(coursesData);
    
            if (coursesData.coursePic) {
                setCoursePicUrl(filepath + '/' + coursesData.coursePic);
            }
    
            // Parse timeSlots if it's an array containing a single JSON string
            if (Array.isArray(coursesData.timeSlots) && coursesData.timeSlots.length > 0) {
                const parsedTimeSlots = JSON.parse(coursesData.timeSlots[0]);
                setTimeSlots(parsedTimeSlots);
            }

            
             // Parse slotDate if it's an array containing a single JSON string
             if (Array.isArray(coursesData.dateSlots) && coursesData.dateSlots.length > 0) {
                const parsedDateSlote = JSON.parse(coursesData.dateSlots[0]);
                SetDateSlots(parsedDateSlote);
            }
    
            // Parse descriptionPoints if it's an array containing a single JSON string
            if (Array.isArray(coursesData.descriptionPoints) && coursesData.descriptionPoints.length > 0) {
                const parsedDescriptionPoints = JSON.parse(coursesData.descriptionPoints[0]);
                setDescriptionPoints(parsedDescriptionPoints);
            }
    
        } catch (error) {
            console.error("Error fetching course details:", error);
        }
    };
    

    const handleUpdate = async (e) => {
        e.preventDefault();

        const formdata = new FormData();
        formdata.append('courseName', formData.courseName);
        formdata.append('description', formData.description);
        formdata.append('instructor', formData.instructor);
        formdata.append('fee', formData.fee);
        formdata.append('timeSlot', formData.timeSlot);
        formdata.append('courseExpire', formData.courseExpire);
        formdata.append('descriptionDetails', formData.descriptionDetails);
        formdata.append('descriptionDetailsText', formData.descriptionDetailsText);
        formdata.append('descriptionPrice', formData.descriptionPrice);
        formdata.append('coursePic', formData.coursePic);
        formdata.append('startTimes', formData.startTimes);
        formdata.append('endTimes', formData.endTimes);
        formdata.append('gst', formData.gst);
        formdata.append('timeSlots', JSON.stringify(timeSlots)); // Convert timeSlots to JSON string
        formdata.append('descriptionPoints', JSON.stringify(descriptionPoints)); // Add descriptionPoints to the request
        formdata.append('priceOffer', formData.priceOffer);
        formdata.append('classModel', formData.classModel); 
        formdata.append('dateSlots', JSON.stringify(dateSlots)); // Add descriptionPoints to the request


        try {
            const response = await axios.put(apipath + `/course/details/${courseId}`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                iziToast.success({
                    message: "Course update successful",
                    position: "topCenter",
                });
                navigate("/coursesDetails");
            } else {
                iziToast.error({
                    message: "Course update failed",
                    position: "topCenter",
                });
            }
        } catch (error) {
            console.error("Error updating course details:", error);
            iziToast.error({
                message: "An error occurred",
                position: "topCenter",
            });
        }
    };


    const handleEditorChange = (event, editor, field) => {
        const data = editor.getData();
        setFormData(prevDetails => ({
            ...prevDetails,
            [field]: data, // Update the specific field
        }));
    };

    const handleAddTimeSlot = () => {
        setTimeSlots([...timeSlots, { startTime: '', endTime: '' }]); // Add a new time slot
    };

    const handleTimeSlotChange = (index, field, value) => {
        const updatedTimeSlots = timeSlots.map((slot, i) =>
            i === index ? { ...slot, [field]: value } : slot
        );
        setTimeSlots(updatedTimeSlots); // Update the state with the modified time slot
    };

    const handleRemoveTimeSlot = (index) => {
        const updatedTimeSlots = timeSlots.filter((_, i) => i !== index);
        setTimeSlots(updatedTimeSlots); // Remove the time slot at the given index
    };

    // Handle adding/removing description points
    const handleAddDescriptionPoint = () => {
        setDescriptionPoints([...descriptionPoints, '']);
    };

    const handleRemoveDescriptionPoint = (index) => {
        const updatedPoints = descriptionPoints.filter((_, i) => i !== index);
        setDescriptionPoints(updatedPoints);
    };

    const handleDescriptionPointChange = (index, value) => {
        const updatedPoints = descriptionPoints.map((point, i) =>
            i === index ? value : point
        );
        setDescriptionPoints(updatedPoints);
    };

 // Handle adding/removing Date points
 const handleAddDateSlot = () => {
    SetDateSlots([...dateSlots, { startDate: '', endDate: '' }]); // Add a new date slot
  };

  const handleDateSlotChange = (index, field, value) => {
    const updatedDateSlots = dateSlots.map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    SetDateSlots(updatedDateSlots); // Update the state with the modified date slot
  };

  const handleRemoveDateSlot = (index) => {
    const updatedDateSlots = dateSlots.filter((_, i) => i !== index);
    SetDateSlots(updatedDateSlots); // Remove the date slot at the given index
  };


    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div class="container pt-3">
                    <div class="row top-barcolumn">
                        <h5>Update Course Information</h5>
                        <div class="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleUpdate}>
                                    <div class="col-lg-6 mb-3">
                                        <label for="nav-items1" class="form-label">
                                            Course Name
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter course Name"
                                            name="courseName"
                                            value={formData.courseName}
                                            onChange={handleChange}
                                            maxLength="100"
                                            required
                                        />
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <label for="nav-items2" class="form-label">
                                            Instructor
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter instructor name"
                                            name="instructor"
                                            value={formData.instructor}
                                            onChange={handleChange}
                                            maxLength="100"
                                            required
                                        />
                                    </div>
                                    <div class="col-lg-6 mb-3">
                    <label for="nav-items1" class="form-label">
                    Course Price Offer
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter priceOffer"
                      name="priceOffer"
                      value={formData.priceOffer}
                      onChange={handleChange}
                      maxLength="5"
                      step="0.01"   
        min="0"
        max="99999"
        autoComplete="off"
        required
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                    Course Class Mode
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Enter class mode"
                      name="classModel"
                      value={formData.classModel}
                      onChange={handleChange}
                      maxLength="100"
                      required
                    />
                  </div>
                                    <div class="col-lg-6 mb-3">
                                        <label for="nav-items2" class="form-label">
                                            Course Fee
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter course fee"
                                            name="fee"
                                            value={formData.fee}
                                            onChange={handleChange}
                                            maxLength="5"
                                            step="0.01"
                                            min="0"
                                            max="99999"
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <label for="nav-items2" class="form-label">
                                            Course GST
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter course gst"
                                            name="gst"
                                            value={formData.gst}
                                            onChange={handleChange}
                                            maxLength="5"
                                            step="0.01"
                                            min="0"
                                            max="99999"
                                            autoComplete="off"
                                            required
                                        />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="nav-item2" className="form-label">
                                            Course Start Time
                                        </label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter time slot"
                                            name="startTimes"
                                            value={formData.startTimes}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="nav-item2" className="form-label">
                                            Course End Time
                                        </label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter time slot"
                                            name="endTimes"
                                            value={formData.endTimes}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div class="col-lg-6 mb-3">
                                        <label for="nav-items2" class="form-label">
                                            Course Expire
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter course expire"
                                            name="courseExpire"
                                            value={formData.courseExpire}
                                            onChange={handleChange}
                                            maxLength="50"
                                        //   required
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                            Designation
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter course description"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            maxLength="1000"
                                            required
                                        ></textarea>
                                    </div>


                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items2" className="form-label">Description Details</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={formData.descriptionDetails}
                                            onChange={(event, editor) => handleEditorChange(event, editor, 'descriptionDetails')}
                                            name="descriptionDetails"
                                        />
                                    </div>

                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items2" className="form-label">Description Details Text</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={formData.descriptionDetailsText}
                                            onChange={(event, editor) => handleEditorChange(event, editor, 'descriptionDetailsText')}
                                            name="descriptionDetailsText"
                                        />
                                    </div>

                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="nav-items2" className="form-label">Description Price</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={formData.descriptionPrice}
                                            onChange={(event, editor) => handleEditorChange(event, editor, 'descriptionPrice')}
                                            name="descriptionPrice"
                                        />
                                    </div>

                                    <div className="change-profile pt-4 pb-4">
                                        {coursePicUrl && (
                                            <img
                                                src={coursePicUrl || `${process.env.PUBLIC_URL}/imgs/imagess.png`}
                                                width={150} height={100}
                                                style={{ borderRadius: '35px' }}
                                                alt="profile-img"
                                                onClick={handleUploadButtonClick}
                                            />)}
                                        <h6>Course Image</h6>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            name="coursePic"
                                            onChange={handleFileChange}
                                        // style={{ display: 'none' }}
                                        />
                                    </div>

                                    {/* Time Slots Section */}
                                    {/* <div className="col-lg-12 mb-3">
                                        <label className="form-label">Course Time Slots</label>
                                        {timeSlots && timeSlots.map((slot, index) => (
                                            <div key={index} className="row mb-3">
                                                <div className="col-lg-5">
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        placeholder="Start Time"
                                                        value={slot.startTime}
                                                        onChange={(e) => handleTimeSlotChange(index, 'startTime', e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-5">
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        placeholder="End Time"
                                                        value={slot.endTime}
                                                        onChange={(e) => handleTimeSlotChange(index, 'endTime', e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleRemoveTimeSlot(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        ))}

                                        <button type="button" className="btn btn-primary" onClick={handleAddTimeSlot}>
                                            Add Time Slot
                                        </button>
                                    </div> */}

  {/* slotDate Points Section */}
  <div className="col-lg-12 mb-3">
                    <label className="form-label">Course Date Slots</label>
                    {dateSlots && dateSlots.map((slot, index) => (
                      <div key={index} className="row mb-3">
                        <div className="col-lg-5">
                          <input
                            type="Date"
                            className="form-control"
                            placeholder="Start Date"
                            value={slot.startDate}
                            onChange={(e) => handleDateSlotChange(index, 'startDate', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="Date"
                            className="form-control"
                            placeholder="End Date"
                            value={slot.endDate}
                            onChange={(e) => handleDateSlotChange(index, 'endDate', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-lg-2">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveDateSlot(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="btn btn-primary" onClick={handleAddDateSlot}>
                      Add Date Slot
                    </button>
                  </div>


                                    {/* Description Points Section */}
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-label">Description Points</label>
                                        {descriptionPoints && descriptionPoints.map((points, index) => (
                                            <div key={index} className="row mb-3">
                                                <div className="col-lg-10">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Description Point ${index + 1}`}
                                                        value={points}
                                                        onChange={(e) => handleDescriptionPointChange(index, e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-2 d-flex align-items-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleRemoveDescriptionPoint(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        ))}

                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddDescriptionPoint}
                                        >
                                            Add Description Point
                                        </button>
                                    </div>
                                    <div className="">
                                        <button type="submit" className="btn btn-success">
                                            Update Course
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CourseEdit;

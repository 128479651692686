import React, { useEffect, useState } from "react";
import {Link, useNavigate } from 'react-router-dom';
import iziToast from "izitoast";
import { useAuth } from '../AuthContext';
import Navbar from "./Navbar";
import Footer from "./Footer";

const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const { apipath } = useAuth();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        apipath+"/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData)
        }
      );

      const result = await response.json();
      localStorage.setItem("token", result.token);
      // console.log(result);
      
      if (result.success === true) {
        iziToast.success({
          message: "Login successful",
          position: "topCenter"
        });
        if(result.user.role === 'admin'){
          localStorage.setItem('adminToken', result.token);
          localStorage.setItem('adminName', result.user.name);
          localStorage.setItem('adminEmail', result.user.email);
          localStorage.setItem('adminId',result.user._id);
          navigate("/main");
        }
        else{
          localStorage.setItem('userName', result.user.name);
          localStorage.setItem('userId',result.user._id);
          localStorage.setItem('userEmail',result.user.email);
          localStorage.setItem('token', result.token);

          const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
          localStorage.removeItem('redirectAfterLogin');
          navigate(redirectTo);
        }
      } else {
        iziToast.error({
          message: "Login failed : "+result.error,
          position: "topCenter"
        });
        console.error("Login failed:", result.error);
      }
    } catch (error) {
      iziToast.error({
        message: "Error during login",
        position: "topCenter"
      });
      console.error("Error during login:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <>
      <Navbar />

      <div className="container-fluid login-main">
        {/* <div className="row"> */}
        <div className="col-lg-6 login-c">
          <div className="col-lg-8 mx-auto login">
            <h1>Welcome Back</h1>
            <h3>Login to continue learning</h3>
            <form onSubmit={handleSubmit}>
            <div className="login-form">
              <label htmlFor="email">Email Address</label>
              <input type="email" placeholder="Enter your email"
                name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required  />

              <label htmlFor="password">Password</label>
              <div class="input-group l-group">
                <input
                  type={showPassword ? "text" : "password"}
                  class="form-control l-control"
                  placeholder="Password"
                  aria-label="password"
                  aria-describedby="basic-addon1"
                  name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required 
                />
                <div class="input-group-prepend l-prepend">
                  <span
                    class="input-group-text l-group-text"
                    id="basic-addon1"
                    onClick={togglePasswordVisibility}
                  >
                    <i className="bi bi-eye"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="login-agree">
              {/* <div>
                <input type="checkbox" required/>
                <span className="agree">Remember Me</span>
              </div> */}
              <div>
                <Link to={"/forgot"} className="text-decoration-none">
                  <span className="forgot">Forgot Password</span>
                </Link>
              </div>
            </div>
            <div className="login-button">
              {/* <Link to="/profile"> */}
                <button>Login</button>
              {/* </Link> */}
            </div>
            <div className="acc">
              <p>
                Don't have an account ?{" "}
                <span>
                  <Link to="/signup">Sign up</Link>
                </span>
              </p>
            </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 login-image">
          <img src="imgs/loginImage.jpeg" alt="" 
          // width={250}
          //         height={450} 
                  />
        </div>
        {/* </div> */}
      </div>

      <Footer classes={true} />
    </>
  );
};

export default Signin;

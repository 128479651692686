import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import axios from "axios";

const BannerImagesEdit = () => {
    const [imageUrl, setImageUrl] = useState(null);
    const { bannerImageId } = useParams();
    const [formData, setFormData] = useState({
        bannerImage: null,
    });

    const navigate = useNavigate();
    const { apipath, filepath } = useAuth();
    const token = localStorage.getItem('token');

    const handleUploadButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                bannerImage: file,  // Store the file object directly
            });
            setImageUrl(URL.createObjectURL(file));  // Set the preview URL
        }
    };

    useEffect(() => {
        fetchBannerEdit();
    }, []);

    const fetchBannerEdit = async () => {
        try {
            const response = await axios.get(
                `${apipath}/banner-images/details/${bannerImageId}`
            );

            if (response.data && response.data.bannerImages) {
                setImageUrl(filepath+ response.data.bannerImages.bannerImage);  // Set the image URL from the response
            }

            setFormData({
                ...formData,
                bannerImage: response.data.bannerImages,  // Set the image file in the form data if needed
            });
             
        } catch (error) {
            console.error("Error fetching banner image details:", error);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('bannerImage', formData.bannerImage);

        try {
            const response = await axios.put(`${apipath}/banner-images/details/${bannerImageId}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,  // Include token if required
                },
            });

            if (response.status === 200) {
                iziToast.success({
                    message: 'Banner image updated successfully',
                    position: 'topCenter',
                });
                navigate('/banner-images-details');
            } else {
                iziToast.error({
                    message: 'Banner image update failed',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error('Error updating banner image:', error);
            iziToast.error({
                message: 'An error occurred',
                position: 'topCenter',
            });
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <h5>Edit Banner Image</h5>
                        <div className="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleUpdate}>
                                    <div className="change-profile pt-4 pb-4">
                                        {imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                width={90}
                                                height={90}
                                                style={{ borderRadius: '35px' }}
                                                alt="profile-img"
                                                onClick={handleUploadButtonClick}
                                            />
                                        ) : (
                                            <p>No image available</p>  // Fallback content if no image
                                        )}
                                        <h6>Banner Image</h6>
                                        <input
                                            hidden
                                            type="file"
                                            id="fileInput"
                                            name="bannerImage"
                                            onChange={handleChange}
                                            style={{ display: 'none' }}
                                        />
                                    </div>

                                    <div className="">
                                        <button type="submit" className="btn btn-success">
                                            Update Banner Image
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BannerImagesEdit;

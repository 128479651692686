import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import Navbar from './Navbar';
import { useAuth } from '../../AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import iziToast from 'izitoast';
import axios from 'axios';

const FaqEdit = () => {

  const dataTableRef = useRef();
  const { faqId } = useParams();

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  }, []);

  const navigate = useNavigate();
  const { apipath, filepath, logout } = useAuth();
  const [formData, setFormData] = useState({
      heading: '',
      text: '',
  });

  const token = localStorage.getItem('token');
  const handleChange = (e) => {
    const { name, value, type } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
};

useEffect(() => {
    fetchFaqsEdit();
  }, []);

const fetchFaqsEdit = async () => {

    try {
      const response = await axios.get(
        apipath + `/faqs/details/${faqId}`
      );
      setFormData(response.data.faqs);
    } catch (error) {
      console.error("Error fetching faqs Details:", error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append('heading', formData.heading);
    formdata.append('text', formData.text);

    try {
        const response = await axios.put(
            apipath + `/faqs/details/${faqId}`,
            formdata,
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            iziToast.success({
                message: 'Faqs update successfully',
                position: 'topCenter',
            });
            navigate('/faq-details');
        } else {
            iziToast.error({
                message: 'Faqs update failed',
                position: 'topCenter',
            });
        }
    } catch (error) {
        console.error('Error updating faqs:', error);
        iziToast.error({
            message: 'An error occurred',
            position: 'topCenter',
        });
    }
};

  return (
    <>
    <SideBar />
    <Navbar />

    <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div class="container pt-3">
                    <div class="row top-barcolumn">
                        <h5>Update Blog Information</h5>
                        <div class="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleRegister}>
                                    <div class="col-lg-12 mb-3">
                                        <label for="nav-items2" class="form-label">
                                        FAQs Questions Heading
                                        </label>
                                        <textarea
                                            type="text"
                                            class="form-control"
                                            id="nav-item2"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter FAQs Questions Heading"
                                            name="heading"
                                            value={formData.heading}
                                            onChange={handleChange}
                                            maxLength="500"
                                            required
                                        ></textarea>
                                    </div>

                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                        FAQs Questions Text
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter FAQs Questions Text"
                                            name="text"
                                            value={formData.text}
                                            onChange={handleChange}
                                            maxLength="500"
                                            required
                                        ></textarea>
                                    </div>

                                    <div className="">
                                        <button type="submit" className="btn btn-success">
                                            Update Faq's
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqEdit;
import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const UserDetails = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null); // Track the user ID to delete
  const [deleteUserRole, setDeleteUserRole] = useState(''); // Track the user role to delete
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const { apipath } = useAuth();

  useEffect(() => {
    fetchUsers();
    if (!loading && users.length > 0) {
      $(dataTableRef.current).DataTable();
  }
}, [loading, users]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(apipath + '/users/details');
      setUsers(response.data.user);
      setIsLoading(false); 
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setIsLoading(false); 
      setLoading(false);
    }
  };

  const handleShow = (id, role) => {
    if (role === 'admin') {
      alert("You cannot delete an admin user.");
      return;
    }
    setDeleteUserId(id);
    setDeleteUserRole(role);
    setShow(true);
  };

  const handleClose = () => {
    setDeleteUserId(null);
    setDeleteUserRole('');
    setShow(false);
  };

  const handleDelete = async () => {
    if (!deleteUserId) return;

    try {
      setIsLoading(true);
      await axios.delete(apipath + `/users/details/${deleteUserId}`);
      await fetchUsers(); // Refresh users after deletion
      setIsLoading(false);
      handleClose(); // Close the modal after deletion
    } catch (error) {
      console.error('Error deleting user:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className='home'>
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className='bi bi-menu'></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this user?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? "Deleting..." : "Delete"}
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>User Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
              <table id="datatable" className="table" ref={dataTableRef}>
              <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>Address</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user._id}>
                        <td>{index + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{user.gender}</td>
                        <td>{user.address}</td>
                        <td>{user.role}</td>
                        <td className="success">
                          <span><i class="fa-solid fa-check"></i> Active</span>
                        </td>
                        {/* <td className="pending">
                          <i class="fa-solid fa-spinner"></i> Inactive
                        </td> */}
                        <td>
                          {/* <Link to="/user-details"><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp; */}
                          <i className="fa-solid fa-trash" onClick={() => handleShow(user._id, user.role)}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserDetails;

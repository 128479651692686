import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import iziToast from 'izitoast';
import { useAuth } from '../AuthContext';

const Reset = () => {

  const [ showPassword, setShowPassword ] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || ''; // Retrieve email passed from the previous component
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { apipath } = useAuth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const handleVisibility = (index) => {
    setShowPassword(showPassword === index ? null : index);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      iziToast.error({
        title: 'Error',
        message: 'Passwords do not match',
      });
      return;
    }

    fetch(apipath + '/mail/update-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password: newPassword }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Failed to update password');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: data.message || 'Password updated successfully',
        });
        navigate("/signin"); // Redirect to login page after successful password reset
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to update password',
        });
      });
  };

  return (
    <>
    <Navbar />

      <div className="container-fluid login-main">
        {/* <div className="row"> */}
        <div className="col-lg-6 login-c">
          <div className="col-lg-8 mx-auto login">
            <h1>Reset Password</h1>
            <h3> </h3>
            <form onSubmit={handleSubmit}>
            <div className="login-form">
                <label htmlFor="email">Enter Email </label>
                <input type="text"
                  placeholder="Enter Your email"
                  id="email"
                  value={email}
                  readOnly/>

              <label htmlFor="newpass">New Password</label>
              <div class="input-group l-group">
              <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter your new password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    id="newPassword"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                  />
                <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(1)}></i>
                    </span>
                  </div>
                </div>
              <label htmlFor="confirmpass">Confirm new Password</label>
              <div class="input-group l-group">
                <input
               type={showPassword ? "text" : "password"}
                    className="form-control"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    placeholder="Enter your confirm password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                />
                 <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(2)}></i>
                    </span>
                  </div>
                </div>
            </div>
            
            <div className="login-button">
              <button>Continue</button>
            </div>
            <div className="acc">
              <p>
                Remembered your password ?{" "}
                <span>
                  <Link to="/signin">Sign in</Link>
                </span>
              </p>
            </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 login-image">
        <img src={`${process.env.PUBLIC_URL}/imgs/loginImage.jpeg`}/>
        </div>
      </div>
      <Footer classes={true} />
    </>
  )
}

export default Reset
import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../../AuthContext';
import axios from 'axios';

const CourseEnquiryDetails = () => {
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [deleteCourseEnquiryId, setDeleteCourseEnquiryId] = useState(null); // Track the user ID to delete
    const [courseEnquiry, setCourseEnquiry] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const { apipath } = useAuth();

    useEffect(() => {
        fetchCourseEnquiry();
        if (!loading && courseEnquiry.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, courseEnquiry]);


    const fetchCourseEnquiry = async () => {
        try {
            const response = await axios.get(apipath + '/course-enquiry/details');
            setCourseEnquiry(response.data.courseEnquiry);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching course enquiry:', error);
            setLoading(false);
        }
    };

    const handleShow = (id) => {
        setDeleteCourseEnquiryId(id);
        setShow(true);
    };

    const handleClose = () => {
        setDeleteCourseEnquiryId(null);
        setShow(false);
    };

    const handleDelete = async () => {
        if (!deleteCourseEnquiryId) return;

        try {
            setIsLoading(true);
            await axios.delete(apipath + `/course-enquiry/details/${deleteCourseEnquiryId}`);
            await fetchCourseEnquiry(); // Refresh users after deletion
            setIsLoading(false);
            handleClose(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting user:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Course Enquiry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this course enquiry?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Course Enquiry Details</h5>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Number</th>
                                            <th>Post a comment</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {courseEnquiry.map((enquiry, index) => (
                                            <tr key={enquiry._id}>
                                                <td>{index + 1}</td>
                                                <td>{enquiry.name}</td>
                                                <td>{enquiry.email}</td>
                                                <td>{enquiry.phone}</td>
                                                <td>{enquiry.comment}</td>
                                                <td>
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(enquiry._id)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CourseEnquiryDetails;

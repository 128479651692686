import React, { useState, useEffect } from "react";
import axios from "axios";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const DetailsEdit = () => {
  const { apipath } = useAuth();
  
  const [contentTexts, setContentTexts] = useState('');
  const [contactDetails, setContactDetails] = useState({
    contentHeading: "",
    contentText: "",
  });

  useEffect(() => {
    fetchFooterDetails();
  }, []);

  const fetchFooterDetails = async () => {
    try {
        const response = await axios.get(
          `${apipath}/homePage/details/66a3c8240788bc05987fc657`
        );
        setContactDetails(response.data.homePage);
        setContentTexts(response.data.homePage.contentText || '');
      } catch (error) {
        console.error("Error fetching Navbar Details:", error);
      }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${apipath}/homePage/details/66a3c8240788bc05987fc657`,
        contactDetails
      );
      if (response.status === 200) {
        iziToast.success({
          message: "Contact details updated successfully!",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Contact details update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error updating Contact details:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setContentTexts(data);
    setContactDetails(prevDetails => ({
        ...prevDetails,
        contentText: data,
      }));
  };

  return (
    <>
   <SideBar />
   <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Content Information</h5>
            <div className="col-lg-5 nav-column">
              <div>
                <form>
                  <div className="mb-3">
                    <label for="nav-items1" className="form-label">
                    Content Heading
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Details"
                        name="contentHeading"
                        value={contactDetails.contentHeading}
                        onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="nav-items1" className="form-label">
                    Content Text  
                    </label>
                    {/* <textarea
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Details"
                      name="contentText"
                        value={contactDetails.contentText}
                        onChange={handleChange}
                    /> */}
                    <CKEditor
                        editor={ClassicEditor}
                        data={contentTexts}
                        onChange={handleEditorChange}
                        name="contentText"
                      />
                  </div>
                  <button type="submit" className="btn btn-success" onClick={handleUpdate}>
                   Update
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsEdit;

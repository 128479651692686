import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../../AuthContext';
import axios from 'axios';

const BookingDetails = () => {
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [deleteBookingId, setDeleteBookingId] = useState(null); // Track the user ID to delete
    const [booking, setBooking] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const { apipath } = useAuth();

    useEffect(() => {
        fetchBooking();
        if (!loading && booking.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, booking]);
  
    const fetchBooking = async () => {
        try {
          const response = await axios.get(apipath + '/booking/details');
          setBooking(response.data.booking);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching booking-details:', error);
          setLoading(false);
        }
      };

    const handleShow = (id) => {
        setDeleteBookingId(id);
        setShow(true);
    };

    const handleClose = () => {
        setDeleteBookingId(null);
        setShow(false);
    };

    const handleDelete = async () => {
        if (!deleteBookingId) return;

        try {
            setIsLoading(true);
            await axios.delete(apipath + `/booking/details/${deleteBookingId}`);
            await fetchBooking(); // Refresh users after deletion
            setIsLoading(false);
            handleClose(); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting user:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this Booking?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Courser Booking Details</h5>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                    <tr> 
                                    <th>SL</th>
                                    {/* <th>User Name</th> */}
                                    <th>Booking Id</th>
                                    <th>Course</th>
                                    <th>Teacher</th>
                                    <th>Price</th>
                                    <th>Booking Status</th>
                                    <th>Payment Status</th>
                                    <th>Action</th>
                                </tr>
                                    </thead>
                                    <tbody>
                                    {booking.map((booking, index) => (
                      <tr key={booking._id}>
                        <td>{index + 1}</td>
                        {/* <td>{booking.name}</td> */}
                        <td>{booking.bookingId}</td>
                        <td>{booking.bookingCourse}</td>
                        <td>{booking.bookingInstructor}</td>
                        <td>${" "}{booking.totalPrice}</td>
                        <td>
  {booking.bookingStatus ? (
    <span>
      <i className="fa-solid fa-check"></i> Complete
    </span>
  ) : (
    <span className="pending">
      <i className="fa-solid fa-spinner"></i> Pending
    </span>
  )}
</td>

<td>
  {booking.paymentStatus ? (
    <span className="success">
      <i className="fa-solid fa-check"></i> Complete
    </span>
  ) : (
    <span className="pending">
      <i className="fa-solid fa-spinner"></i> Pending
    </span>
  )}
</td>

                        <td>
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(booking._id)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BookingDetails;

import './App.css';
import Blogdetails from './components/Blogdetails';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Courses from './components/Courses';
import Details from './components/Details';
import Forgot from './components/Forgot';
import Landing from './components/Landing';
import Login from './components/Login';
import Price from './components/Price';
import Privacy from './components/Privacy';
import Profile from './components/Profile';
import Reset from './components/Reset';
import Signin from './components/Signin';
import Terms from './components/Terms';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from './components/admin/Main';
import AdminTable from './components/admin/AdminTable';
import LogoEdit from './components/admin/LogoEdit';
import NavbarEdit from './components/admin/NavbarEdit';
import FooterEdit from './components/admin/FooterEdit';
import HomeEdit from './components/admin/HomeEdit';
import DetailsEdit from './components/admin/DetailsEdit';
import BlogsEdit from './components/admin/BlogsEdit';
import CourseEdit from './components/admin/CourseEdit';
import FooterContentEdit from './components/admin/FooterContentEdit';
import FaqEdit from './components/admin/FaqEdit';
import ContactSubmit from './components/Contactsubmit';
import BlogCommentDetails from './components/admin/BlogCommentDetails';
import UserDetails from './components/admin/UserDetails';
import BlogContentEdit from './components/admin/BlogContentEdit';
import CoursesDetails from './components/admin/CoursesDetails';
import CourseAdd from './components/admin/CourseAdd';
import BlogsDetails from './components/admin/BlogsDetails';
import BlogAdd from './components/admin/BlogAdd';
import ContactUs from './components/admin/ContactUs';
import BannersEdit from './components/admin/BannersEdit';
import FaqsDetails from './components/admin/FaqsDetails';
import FaqsAdd from './components/admin/FaqsAdd';
import BannerImagesDetails from './components/admin/BannerImagesDetails';
import BannerImagesAdd from './components/admin/BannerImagesAdd';
import BannerImagesEdit from './components/admin/BannerImagesEdit';
import BlogBannerImageEdit from './components/admin/BlogBannerImageEdit';
import RegOtpVerify from './components/RegOtpVerify';
import Congrats from './components/Congrats';
import Test from './components/Test';
import MyBookingDetails from './components/MyBookingDetails';
import BookingDetails from './components/admin/BookingDetails';
import CourseEnquiryDetails from './components/admin/CourseEnquiryDetails';
import AboutPayment from './components/admin/AboutPayment';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/details/:courseId" element={<Details />} />
          <Route exact path="/courses" element={<Courses />} /> 
         <Route exact path="/blogDetails/:blogId" element={<Blogdetails />} />
         <Route exact path="/blogs" element={<Blogs />} />
         <Route exact path="/contact" element={<Contact />} />
         <Route exact path="/about-payment" element={<AboutPayment />} />
         <Route exact path="/signup" element={<Login />} />
         <Route exact path="/signin" element={<Signin />} />
         <Route exact path="/profile/:userId" element={<Profile />} />
         <Route exact path="/price/:courseId" element={<Price />} />
         <Route exact path="/forgot" element={<Forgot />} />
         <Route exact path="/reset" element={<Reset />} />
         <Route exact path="/contactSubmit" element={<ContactSubmit />} />        
         <Route exact path="/blogComment" element={<BlogCommentDetails />} /> 
         <Route exact path="/otp-verify/:userId" element={<RegOtpVerify />} />
         <Route exact path="/congrats/:bookingId" element={<Congrats />} />
         <Route exact path="/my-booking" element={<MyBookingDetails />} />
         <Route exact path="/booking-details" element={<BookingDetails />} />
         <Route exact path="/enquiry-details" element={<CourseEnquiryDetails />} />
         <Route exact path="/test/:id" element={<Test />} />
         

          {/* ADMIN */}

         <Route exact path="/main" element={<Main />} />
         <Route exact path="/table" element={<AdminTable />} /> 
         <Route exact path="/logo" element={<LogoEdit />} /> 
         <Route exact path="/blog-banner-image" element={<BlogBannerImageEdit />} /> 
         <Route exact path="/navedit" element={<NavbarEdit />} /> 
         <Route exact path="/footeredit" element={<FooterEdit />} /> 
         <Route exact path="/homeedit" element={<HomeEdit />} /> 
         <Route exact path="/detailsedit" element={<DetailsEdit />} /> 
         <Route exact path="/blogsedit/:blogId" element={<BlogsEdit />} /> 
         <Route exact path="/courseedit/:courseId" element={<CourseEdit />} /> 
         <Route exact path="/footerCedit" element={<FooterContentEdit />} /> 
         <Route exact path="/usersedit" element={<UserDetails />} /> 
         <Route exact path="/blog-content" element={<BlogContentEdit />} /> 
         <Route exact path="/coursesDetails" element={<CoursesDetails />} />
         <Route exact path="/add-course" element={<CourseAdd />} />
         <Route exact path="/blogsDetails" element={<BlogsDetails />} />
         <Route exact path="/blogAdd" element={<BlogAdd />} />
         <Route exact path="/contactUs" element={<ContactUs />} />
         <Route exact path="/banners" element={<BannersEdit />} />
         <Route exact path="/faq-edit/:faqId" element={<FaqEdit />} /> 
         <Route exact path="/faq-details" element={<FaqsDetails />} />
         <Route exact path="/faq-add" element={<FaqsAdd />} />
         <Route exact path="/banner-images-details" element={<BannerImagesDetails />} />
         <Route exact path="/add-banner-image" element={<BannerImagesAdd />} />
         <Route exact path="/edit-banner-image/:bannerImageId" element={<BannerImagesEdit />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
